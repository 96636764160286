import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import FormatQuote from "@material-ui/icons/FormatQuote";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import CardAvatar from "components/Card/CardAvatar.js";
import Table from "components/Table/Table.js";
import BanglesHeader from "views/BanglesPage/Section/BanglesHeader.js";
import HeaderLink from "views/BanglesPage/Section/BanglesHeaderLink.js";
import BanglesFooter from "views/BanglesPage/Section/BanglesFooter.js";

import testimonialsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/testimonialsStyle.js";

import cardProfile1Square from "assets/img/bangle-size-guide.jpg";

const useStyles = makeStyles(testimonialsStyle);

export default function InshaBanglesSizeGuide({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      <Helmet>
        <title>Bangles Size Guide</title>
      </Helmet>
       <BanglesHeader
        brand="Home"
        links={<HeaderLink dropdownHoverColor="info" />}
        fixed
        // color="transparent"
        color="info"
        changeColorOnScroll={{
          height: 100,
          color: "info",
      }}
      />
      <div
        className={classes.testimonials}
      >
        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={12}
              md={6}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Bangle Org Size Guide</h2>
              <h5 className={classes.description}>
              This is a chart that will be extremely helpful for you to determine the bangle size that you need.
              </h5>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                  Bangles Size (Indian MM/Gram)
                  </h5>
                  <Table  
                  tableData={[
                      ["1x8", "38.1MM"],
                      ["1x10", "41.2MM/0.063 Gram"],
                      ["1x12", "44.4MM/0.068 Gram"],
                      ["1x14", "47.6MM"],
                      ["2x0", "50.8MM/0.076 Gram"],
                      ["2x2", "53.9MM"],
                      ["2x4", "57.1MM/0.079 Gram"],
                      ["2x6", "60.3MM"],
                      ["2x8", "63.5MM/0.096 Gram"],
                      ["2x10", "66.6MM/0.100 Gram"],
                      ["2x12", "69.8MM/0.103 Gram"],
                      ["2x14", "73.0MM"],
                      ["3x0", "76.3MM"] 
                      ]} />
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Steps to know your Bangles Size:</h4>
                  <h6 className={classes.cardCategory}>1. TAKE A BANGLE AND PLACE IT ON A SCALE.<br /> 2. MEASURE THE INNER DIAMETER AS SHOWN IN THE PICTURE.<br /> 3. USE THE ABOVE CHART TO DETERMINE YOUR BANGLE SIZE</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={cardProfile1Square} alt="..." />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                  MM/Inches
                  </h5>
                  <Table  
                  tableData={[
                      ["1x8", "38.1MM/1.5 Inch"],
                      ["1x10", "41.2MM/1.622 Inch"],
                      ["1x12", "44.4MM/1.74 Inch"],
                      ["1x14", "47.6MM/1.87 Inch"],
                      ["2x0", "50.8MM/2 Inch"],
                      ["2x2", "53.9MM/2.12 Inch"],
                      ["2x4", "57.1MM/2.24 Inch"],
                      ["2x6", "60.3MM/2.37 Inch"],
                      ["2x8", "63.5MM/2.5 Inch"],
                      ["2x10", "66.6MM/2.62 Inch"],
                      ["2x12", "69.8MM/2.74 Inch"],
                      ["2x14", "73.0MM/2.87 Inch"],
                      ["3x0", "76.3MM/3 Inch"] 
                      ]} />
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Steps to know your Bangles Size:</h4>
                  <h6 className={classes.cardCategory}>1. TAKE A BANGLE AND PLACE IT ON A SCALE.<br /> 2. MEASURE THE INNER DIAMETER AS SHOWN IN THE PICTURE.<br /> 3. USE THE ABOVE CHART TO DETERMINE YOUR BANGLE SIZE</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={cardProfile1Square} alt="..." />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <Card testimonial className={classes.card1}>
                <div className={classes.icon}>
                  <FormatQuote />
                </div>
                <CardBody>
                  <h5 className={classes.cardDescription}>
                  Bangles Size (US MM/Gram)
                  </h5>
                  <Table  
                  tableData={[
                      ["#1", "41.2MM/0.063 Gram"],
                      ["#2", "44.4MM/0.068 Gram"],
                      ["#3", "50.8MM/0.076 Gram"],
                      ["#4", "57.1MM/0.079 Gram"],
                      ["#5", "63.5MM/0.096 Gram"],
                      ["#6", "66.6MM/0.100 Gram"],
                      ["#7", "69.8MM/0.0103 Gram"],
                      ["#8", "76.3MM"],
                      ]} />
                </CardBody>
                <CardFooter testimonial>
                  <h4 className={classes.cardTitle}>Steps to know your Bangles Size:</h4>
                  <h6 className={classes.cardCategory}>1. TAKE A BANGLE AND PLACE IT ON A SCALE.<br /> 2. MEASURE THE INNER DIAMETER AS SHOWN IN THE PICTURE.<br /> 3. USE THE ABOVE CHART TO DETERMINE YOUR BANGLE SIZE</h6>
                  <CardAvatar testimonial testimonialFooter>
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img src={cardProfile1Square} alt="..." />
                    </a>
                  </CardAvatar>
                </CardFooter>
              </Card>
            </GridItem> 
          </GridContainer>
        </div>
      </div>
      <BanglesFooter />
      </div>
  );
}