import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// library used for cool animations
// import ScrollAnimation from "react-animate-on-scroll";
import "animate.css/animate.min.css";

import contentStyle from "assets/jss/material-kit-pro-react/views/contentStyle.js";
// images

import Form102Certificate from "assets/img/Form102Certificate.png"
import FormBCertificate from "assets/img/FormBCertificate.png"
import FormDCertificate from "assets/img/FormDCertificate.png"
import CertificateIEC from "assets/img/CertificateIEC.png"
import AadharRegistration from "assets/img/Udyog-Aadhaar-Registration.png"
import GstRegistration from "assets/img/Form-GST-REG-06.png"
import ISO from "assets/img/ISO-Certificate-9001-2015.png"

const useStyles = makeStyles(contentStyle);

export default function LicencesDescription() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <div className={classes.container}>
        <GridContainer justify="center">
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Maharashtra Shops And Establishments Act 1948</h3>
              <h6 className={classes.description}>
                Registration Certificate of Establishment
              </h6>
              <h5 className={classes.description}>
              Acquired the Factory Municipality Licence from the Government of India
              </h5>
            </div>
          </GridItem>
          <GridItem md={8} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <img
                className={classes.ipadImg}
                src={FormDCertificate}
                alt="iPad"
              />
            </div>
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <GridContainer justify="center">
          <GridItem md={8} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <img
                className={classes.ipadImg}
                src={Form102Certificate}
                alt="iPad"
              />
            </div>
          </GridItem>
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Certificate of Registration</h3>
              <h6 className={classes.description}>
                The Maharashtra Value Added Tax Act, 2002
              </h6>
              <h5 className={classes.description}>
              Acquired the Tax Certificate of Registration from the Government of Maharashtra
              </h5>
            </div>
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <GridContainer justify="center">
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Certificate of Registration</h3>
              <h6 className={classes.description}>
                The Central Sales Tax (Registration & Turnover) Rules, 1957
              </h6>
              <h5 className={classes.description}>
              Acquired the Central Tax Certificate of Registration from the Government of Maharashtra
              </h5>
            </div>
          </GridItem>
          <GridItem md={8} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <img
                className={classes.ipadImg}
                src={FormBCertificate}
                alt="iPad"
              />
            </div>
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <GridContainer justify="center">
          <GridItem md={8} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <img
                className={classes.ipadImg}
                src={CertificateIEC}
                alt="iPad"
              />
            </div>
          </GridItem>
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Government of India</h3>
              <h6 className={classes.description}>
                Certificate of Importer-Exporter Code (IEC)
              </h6>
              <h5 className={classes.description}>
              Acquired the Importer & Exporter Certificate from the Government of India
              </h5>
            </div>
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <GridContainer justify="center">
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Govt of India</h3>
              <h6 className={classes.description}>
                Udyog Aadhaar Registration Certificate
              </h6>
              <h5 className={classes.description}>
              Acquired the Udyog Aadhaar Certificate from the Government of India
              </h5>
            </div>
          </GridItem>
          <GridItem md={8} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <img
                className={classes.ipadImg}
                src={AadharRegistration}
                alt="iPad"
              />
            </div>
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <GridContainer justify="center">
          <GridItem md={8} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <img
                className={classes.ipadImg}
                src={GstRegistration}
                alt="iPad"
              />
            </div>
          </GridItem>
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>Government of India</h3>
              <h6 className={classes.description}>
                GST Registration Certificate
              </h6>
              <h5 className={classes.description}>
              Acquired the GST Certificate from the Government of India
              </h5>
            </div>
          </GridItem>
        </GridContainer>
        <br />
        <br />
        <GridContainer justify="center">
          <GridItem md={4}>
            <div className={classes.sectionDescription}>
              <h3 className={classes.title}>ISO Certificate of Registration</h3>
              <h6 className={classes.description}>
                ISO 9001:2015 (Quality Management System)
              </h6>
              <h5 className={classes.description}>
              Acquired the ISO Certificate from the Government of India
              </h5>
            </div>
          </GridItem>
          <GridItem md={8} className={classes.mlAuto}>
            <div className={classes.imageContainer}>
              <img
                className={classes.ipadImg}
                src={ISO}
                alt="iPad"
              />
            </div>
          </GridItem>
        </GridContainer>
        <br />
        <br />
      </div>
    </div>
  );
}
