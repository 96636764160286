/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "@material-ui/core";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import NavPills from "components/NavPills/NavPills.js";
import CardFooter from "components/Card/CardFooter.js";
import { FiPhoneCall } from "react-icons/fi";
import Header from "components/Header/Header.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import loginPageStyle from "assets/jss/material-kit-pro-react/views/loginPageStyle.js";
// import styles from "assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";

import { cardTitle } from "assets/jss/material-kit-pro-react.js";

const style = {
  cardTitle,
  pageSubcategoriesTitle: {
    color: "#3C4858",
    textDecoration: "none",
    textAlign: "center",
  },
  cardCategory: {
    margin: "0",
    color: "#999999",
  },
};

const useStyles = makeStyles(loginPageStyle);
const useStyle = makeStyles(style);

export default function BanglesLogin() {
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [otpNumber, setOtpNumber] = React.useState("");
  const [otpNumberErr, setOtpNumberErr] = React.useState("");
  const [otpScreen, setOtpScreen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [reSend, setReSend] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [admin, setAdmin] = React.useState("");

  React.useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  const classes = useStyles();
  const history = useHistory();
  const classe = useStyle();
  // const headApi = `https://api.bangles.org.in/api/rest`;

  // const currentSession = sessionStorage.getItem("sessionData");
  // {currentSession ? history.push("/product-list") : history.push("/bangles-login")}
  // if (currentSession) {
  //   history.push("/product-list");
  // }

  async function getAdmin() {
    const api = localApi + `/admin/allList`;
    const result = await fetch(api);
    const getResult = await result.json();
    // console.log(getResult.AdminList);
    setAdmin(getResult.AdminList);
  }

  useEffect(() => {
    getAdmin();
  }, []);

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    // setMobileErr("");
    fetch(localApi + `/register/find/byMobile/${mobile}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // setMobileErr(result.message);
          if (result.message === "Mobile No. Already Registered") {
            setMobileErr("");
          } else if (result.message === "Not Found") {
            setMobileErr("Your No. Not Registered");
          } else {
            setMobileErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    fetch(localApi + `/register/find/byEmail/${value}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // setEmailErr(result.message);
          if (result.message === "Email ID Already Registered") {
            setEmailErr("");
          } else if (result.message === "Not Found") {
            setEmailErr("Your Email Not Registered");
          } else {
            setEmailErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleOtp = (e) => {
    // console.log(e.target.value);
    setOtpNumber(e.target.value);
    setOtpNumberErr("");
  };

  const handleSendOtpWithMobile = async () => {
    const getFindAdmin = admin.find((f) => f.websitename === "bangles.org.in");
    const api = localApi + `/register/byMobile/${mobile}`;
    const result = await fetch(api);
    const getResult = await result.json();
    let errorCount = 0;
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Mobile Number is Invalid");
    }
    if (errorCount === 0) {
      fetch(
        localApi +
        `/otp/send/byMobile?mobile=${mobile}&templateID=1207165062504687970`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify({
            websitename: "bangles.org.in",
            followId: getFindAdmin.id,
            adminId: getResult.aId,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setMobileErr(response.message);
          if (response && response.error === true) {
            setOtpScreen({ otpScreen: true });
            setOtp(response.message);
          }
          // "Sender Not Found to +919967457786"
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSendOtpWithEmail = async () => {
    const getFindAdmin = admin.find((f) => f.websitename === "bangles.org.in");
    const api = localApi + `/register/byEmail/${email}`;
    const result = await fetch(api);
    const getResult = await result.json();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        to: email,
        subject: "API CREATION SUBJECT",
        websitename: "bangles.org.in",
        followId: getFindAdmin.id,
        adminId: getResult.aId,
      }),
    };
    fetch(
      localApi + `/otp/send/byEmail?e=noreply@bangles.org.in`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        setEmailErr(response.message);
        if (response && response.error === true) {
          setOtpScreen({ otpScreen: true });
          setOtp(response.message);
        }
        // "Sender Not Found to +919967457786"
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoginWithMobile = () => {
    let errorCount = 0;
    if (otpNumber === "" || !otpNumber) {
      errorCount++;
      setOtpNumberErr("Enter Otp Number");
    }
    if (errorCount === 0) {
      fetch(
        localApi +
        `/register/recieved/byMobile?mobile=${mobile}&otp=${otpNumber}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setOtpNumberErr(response.message);
          if (response.errorDetails.error === true) {
            const userObj = response.registerSession;
            localStorage.setItem("userObj", JSON.stringify(userObj));
            sessionStorage.setItem("sessionData", JSON.stringify(userObj));
            history.push("/product-list");
          }
          // if (response.apiResponse.error !== "false") return false;
          // const userObj = response.user;
          // localStorage.setItem("userObj", JSON.stringify(userObj));
          // sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          // history.push("/admin/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLoginWithEmail = () => {
    let errorCount = 0;
    if (otpNumber === "" || !otpNumber) {
      errorCount++;
      setOtpNumberErr("Enter Otp Number");
    }
    if (errorCount === 0) {
      fetch(
        localApi + `/register/recieved/byEmail?email=${email}&otp=${otpNumber}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setOtpNumberErr(response.message);
          if (response.errorDetails.error === true) {
            const userObj = response.registerSession;
            localStorage.setItem("userObj", JSON.stringify(userObj));
            sessionStorage.setItem("sessionData", JSON.stringify(userObj));
            history.push("/product-list");
          }
          // if (response.apiResponse.error !== "false") return false;
          // const userObj = response.user;
          // localStorage.setItem("userObj", JSON.stringify(userObj));
          // sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          // history.push("/admin/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <Helmet>
        <title>Bangles Log In</title>
      </Helmet>
      <Header
        fixed
        color="transparent"
        brand="Home"
      // links={<HeaderLinks dropdownHoverColor="info" />}
      />
      <div
        className={classes.pageHeader}
      // style={{
      //     backgroundImage: "url(" + image + ")",
      //     backgroundSize: "cover",
      //     backgroundPosition: "top center",
      // }}
      >
        <div className={classes.container}>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4}>
              <Card>
                {!otpScreen ? (
                  <form className={classes.form}>
                    <CardHeader
                      color="info"
                      signup
                      className={classes.cardHeader}
                    >
                      <h4 className={classes.cardTitle}>Login</h4>
                      <div className={classes.socialLine}>
                        <Button
                          justIcon
                          color="transparent"
                          className={classes.iconButtons}
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-twitter" />
                        </Button>
                        <Button
                          justIcon
                          color="transparent"
                          className={classes.iconButtons}
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-facebook" />
                        </Button>
                        <Button
                          justIcon
                          color="transparent"
                          className={classes.iconButtons}
                          onClick={(e) => e.preventDefault()}
                        >
                          <i className="fab fa-google-plus-g" />
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <GridContainer justify="center">
                        <GridItem xs={12} sm={12} md={12}>
                          <NavPills
                            color="info"
                            alignCenter
                            tabs={[
                              {
                                tabButton: "Mobile",
                                tabIcon: PhoneAndroidIcon,
                                tabContent: (
                                  <>
                                    {/* <CardBody> */}
                                    <GridContainer>
                                      <GridItem xs={12} sm={12} md={12}>
                                        <PhoneInput
                                          inputStyle={{
                                            width: "100%",
                                            border: "none",
                                            borderBottom: "1px solid #040000 ",
                                            borderRadius: "0",
                                          }}
                                          country={"in"}
                                          name="mobile"
                                          value={mobile}
                                          onChange={(mobile) => {
                                            handleMobileNumber(mobile);
                                          }}
                                          required={true}
                                        />
                                        <span style={{ color: "red" }}>
                                          {mobileErr}
                                        </span>
                                      </GridItem>
                                      <Button
                                        color="info"
                                        simple
                                        // size="lg"
                                        block
                                        onClick={handleSendOtpWithMobile}
                                      >
                                        Login
                                      </Button>
                                    </GridContainer>
                                    {/* </CardBody> */}
                                    {/* <CardFooter
                                      className={classe.justifyContentCenter}
                                    >
                                      <Button
                                        color="info"
                                        simple
                                        // size="lg"
                                        block
                                        onClick={handleSendOtpWithMobile}
                                      >
                                        Login
                                      </Button>
                                    </CardFooter> */}
                                  </>
                                ),
                              },
                              {
                                tabButton: "Email",
                                tabIcon: EmailIcon,
                                tabContent: (
                                  <>
                                    {/* <CardBody> */}
                                    <GridContainer>
                                      <GridItem xs={12} sm={12} md={12}>
                                        <CustomInput
                                          formControlProps={{
                                            fullWidth: true,
                                            className:
                                              classes.customFormControlClasses,
                                          }}
                                          inputProps={{
                                            onChange: (e) => handleEmail(e),
                                            id: "email",
                                            value: email,
                                            name: "email",
                                            placeholder: "Email...",
                                          }}
                                          required={true}
                                        />
                                        <span style={{ color: "red" }}>
                                          {emailErr}
                                        </span>
                                      </GridItem>
                                      <Button
                                        color="info"
                                        simple
                                        // size="lg"
                                        block
                                        onClick={handleSendOtpWithEmail}
                                      >
                                        Login
                                      </Button>
                                    </GridContainer>
                                    {/* </CardBody> */}
                                    {/* <CardFooter
                                      className={classe.justifyContentCenter}
                                    >
                                      <Button
                                        color="info"
                                        simple
                                        // size="lg"
                                        block
                                        onClick={handleSendOtpWithEmail}
                                      >
                                        Login
                                      </Button>
                                    </CardFooter> */}
                                  </>
                                ),
                              },
                            ]}
                          />
                          <div className={classes.textCenter}>
                            <Button
                              simple
                              color="info"
                              // size="lg"
                              href="/bangles-sign-up"
                            >
                              Create New Account
                            </Button>
                          </div>
                        </GridItem>
                      </GridContainer>
                    </CardBody>
                  </form>
                ) : (
                  <form className={classes.form}>
                    {/* <CardHeader></CardHeader> */}
                    <br />
                    <p
                      className={classes.description + " " + classes.textCenter}
                      style={{ marginLeft: "10px", marginRight: "10px" }}
                    >
                      6 digit OTP SMS sent on your {mobile ? "Mobile" : "Email"}{" "}
                      {otp} {reSend}
                    </p>
                    <CardBody signup>
                      <CustomInput
                        id="otpNumber"
                        formControlProps={{
                          fullWidth: true,
                        }}
                        inputProps={{
                          name: "otpNumber",
                          value: otpNumber,
                          onChange: (e) => handleOtp(e),
                          placeholder: "Enter OTP here",
                          type: "text",
                          // endAdornment: (
                          //     <InputAdornment position="end">
                          //         <Icon className={classes.inputIconsColor}>
                          //             lock_utline
                          //         </Icon>
                          //     </InputAdornment>
                          // ),
                          autoComplete: "off",
                        }}
                        required={true}
                      />
                      <p
                        className={
                          classes.description + " " + classes.textCenter
                        }
                        style={{ color: "red" }}
                      >
                        {otpNumberErr}
                      </p>
                      <div className={classes.textCenter}>
                        <Button
                          simple
                          color="info"
                          // size="lg"
                          onClick={
                            mobile
                              ? handleLoginWithMobile
                              : handleLoginWithEmail
                          }
                        >
                          Log in
                        </Button>
                        <Button
                          simple
                          color="info"
                        // size="lg"
                        // onClick={handleCall}
                        >
                          <FiPhoneCall /> Call Me
                        </Button>
                      </div>
                    </CardBody>
                    <p
                      className={classes.description + " " + classes.textCenter}
                    >
                      {" "}
                      Didn{"'"}t receive the OTP yet?{" "}
                      <Button
                        color="rose"
                        simple
                        onClick={
                          mobile
                            ? handleSendOtpWithMobile
                            : handleSendOtpWithEmail
                        }
                      >
                        Resend OTP
                      </Button>
                    </p>
                  </form>
                )}
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
    </div>
  );
}
