/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useLocation } from "react-router";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Tooltip from "@material-ui/core/Tooltip";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import Table from "components/Table/Table.js";
import Close from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import NavPills from "components/NavPills/NavPills.js";
import EmailIcon from "@material-ui/icons/Email";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import CardFooter from "components/Card/CardFooter.js";
// import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
import { FiPhoneCall } from "react-icons/fi";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import SweetAlert from "react-bootstrap-sweetalert";
import BillHeader from "views/BanglesPage/Cart/BillHeader";
import ClientDetails from "views/BanglesPage/Cart/ClientDetails";
import invoiceStyle from "assets/jss/material-kit-pro-react/views/cartStyle";
import listCart from "assets/jss/material-kit-pro-react/views/shoppingCartStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";
import style from "assets/jss/material-kit-pro-react/components/buttonStyle.js";

import axios from "axios";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";

const useStyles = makeStyles(invoiceStyle);
const useStyle = makeStyles(listCart);
const useStylPop = makeStyles(popOver);
const useStyleAlert = makeStyles(style);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function EorderForm() {
  const [addCart, setAddCart] = React.useState([]);
  const [load, setLoad] = React.useState(false);
  const [loginModal, setLoginModal] = React.useState(false);
  const [mobile, setMobile] = React.useState("");
  const [mobileErr, setMobileErr] = React.useState("");
  const [otpNumber, setOtpNumber] = React.useState("");
  const [otpNumberErr, setOtpNumberErr] = React.useState("");
  const [otpScreen, setOtpScreen] = React.useState(false);
  const [otp, setOtp] = React.useState("");
  const [cardList, setCardList] = React.useState([]);
  const [email, setEmail] = React.useState("");
  const [emailErr, setEmailErr] = React.useState("");
  const [admin, setAdmin] = React.useState("");

  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();
  const classPop = useStylPop();
  const classeAlert = useStyleAlert();

  const search = useLocation().search;
  const byuserid = new URLSearchParams(search).get("byuserid");
  const clientid = new URLSearchParams(search).get("clientid");
  const expdate = new URLSearchParams(search).get("expdate");

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  // const date = Intl.DateTimeFormat("fr-CA", {
  //   year: "numeric",
  //   month: "numeric",
  //   day: "numeric",
  // }).format(new Date()).replaceAll('/', '-');

  // const current = new Date();
  // const dateCurrent = `${current.getDate()}-${current.getMonth() + 1}-${current.getFullYear()}`;
  // current.setDate(current.getDate() + 10);
  // let dateEx = new Date(current).toLocaleDateString("de");

  useEffect(() => {
    const fetchAdminData = async () => {
      try {
        const response = await fetch(localApi + `/admin/allList`);
        const json = await response.json();
        setAdmin(json.AdminList);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchAdminData();
  }, []);

  async function getList() {
    const get = localApi + `/register/byMobile/${clientid}`; //919051979509
    const results = await fetch(get);
    const res = await results.json();
    // console.log(res);

    if (res.message === "Not Found") {
      history.push("/error-page");
    }

    const response = await fetch(localApi + `/clientGroup/allList`);
    const json = await response.json();
    const cList = json.ClientList.filter((f) => f.regId === res.id)[0];
    // console.log(cList);

    const list =
      localApi +
      `/cart/expireCart/byAdminAndClientIdAndAcitve/${cList.adminId}/${cList.id}?active=0`;
    const result = await fetch(list);
    const data = await result.json();

    if (data.error === "true") {
      // console.clear();
      setLoad(false);
      history.push("/error-page");
    } else {
      setLoad(true);
      setAddCart(data && data.length != 0 ? data : history.push("/error-page"));
      // const UpDate =
      //   data && data ? data.CartData[0].timedate.update : "";
      // const news = new Date(UpDate);
      // const getDate = new Date(
      //   new Date(news).setDate(new Date(news).getDate() + 3)
      // );
      // setUpdateDate(getDate.toLocaleDateString("de"));
    }
  }

  useEffect(() => {
    getList();
  }, []);

  const handleMobileNumber = (mobile) => {
    setMobile(mobile);
    // setMobileErr("");
    fetch(localApi + `/register/find/byMobile/${mobile}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // console.log(result);
          // setMobileErr(result.message);
          if (result.message === "Mobile No. Already Registered") {
            setMobileErr("");
          } else if (result.message === "Not Found") {
            setMobileErr("Your No. Not Registered");
          } else {
            setMobileErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleEmail = (e) => {
    const { value } = e.target;
    setEmail(value);
    fetch(localApi + `/register/find/byEmail/${value}`)
      .then((res) => res.json())
      .then(
        (result) => {
          // setEmailErr(result.message);
          if (result.message === "Email ID Already Registered") {
            setEmailErr("");
          } else if (result.message === "Not Found") {
            setEmailErr("Your Email Not Registered");
          } else {
            setEmailErr(result.message);
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const handleOtp = (e) => {
    // console.log(e.target.value);
    setOtpNumber(e.target.value);
    setOtpNumberErr("");
  };

  const handleSendOtpWithMobile = async () => {
    // console.log("okkkk");
    const getFindAdmin = admin.find((f) => f.websitename === "bangles.org.in");
    const api = localApi + `/register/byMobile/${mobile}`;
    const result = await fetch(api);
    const getResult = await result.json();
    let errorCount = 0;
    if (mobile === "" || !mobile) {
      errorCount++;
      setMobileErr("Mobile Number is Invalid");
    }
    if (errorCount === 0) {
      fetch(
        localApi +
          `/otp/send/byMobile?mobile=${mobile}&templateID=1207161762762132153`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
          body: JSON.stringify({
            websitename: "bangles.org.in",
            followId: getFindAdmin.id,
            adminId: getResult.aId,
          }),
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setMobileErr(response.message);
          if (response && response.error === true) {
            setOtpScreen({ otpScreen: true });
            setOtp(response.message);
          }
          // "Sender Not Found to +919967457786"
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleSendOtpWithEmail = async () => {
    const getFindAdmin = admin.find((f) => f.websitename === "bangles.org.in");
    const api = localApi + `/register/byEmail/${email}`;
    const result = await fetch(api);
    const getResult = await result.json();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        to: email,
        subject: "API CREATION SUBJECT",
        websitename: "bangles.org.in",
        followId: getFindAdmin.id,
        adminId: getResult.aId,
      }),
    };
    fetch(
      localApi + `/otp/send/byEmail?e=noreply@bangles.org.in`,
      requestOptions
    )
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        setEmailErr(response.message);
        if (response && response.error === true) {
          setOtpScreen({ otpScreen: true });
          setOtp(response.message);
        }
        // "Sender Not Found to +919967457786"
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleLoginWithMobile = () => {
    let errorCount = 0;
    if (otpNumber === "" || !otpNumber) {
      errorCount++;
      setOtpNumberErr("Enter Otp Number");
    }
    if (errorCount === 0) {
      fetch(
        localApi +
          `/register/recieved/byMobile?mobile=${mobile}&otp=${otpNumber}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setOtpNumberErr(response.message);
          if (response.errorDetails.error === true) {
            const userObj = response.registerSession;
            localStorage.setItem("userObj", JSON.stringify(userObj));
            sessionStorage.setItem("sessionData", JSON.stringify(userObj));
            history.push("/cart-shipping-address");
          }
          // if (response.apiResponse.error !== "false") return false;
          // const userObj = response.user;
          // localStorage.setItem("userObj", JSON.stringify(userObj));
          // sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          // history.push("/admin/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const handleLoginWithEmail = () => {
    let errorCount = 0;
    if (otpNumber === "" || !otpNumber) {
      errorCount++;
      setOtpNumberErr("Enter Otp Number");
    }
    if (errorCount === 0) {
      fetch(
        localApi + `/register/recieved/byEmail?email=${email}&otp=${otpNumber}`,
        {
          method: "PUT",
          headers: {
            "content-type": "application/json",
            accept: "application/json",
          },
        }
      )
        .then((response) => response.json())
        .then((response) => {
          // console.log(response);
          setOtpNumberErr(response.message);
          if (response.errorDetails.error === true) {
            const userObj = response.registerSession;
            localStorage.setItem("userObj", JSON.stringify(userObj));
            sessionStorage.setItem("sessionData", JSON.stringify(userObj));
            history.push("/cart-shipping-address");
          }
          // if (response.apiResponse.error !== "false") return false;
          // const userObj = response.user;
          // localStorage.setItem("userObj", JSON.stringify(userObj));
          // sessionStorage.setItem("sessionData", JSON.stringify(userObj));
          // history.push("/admin/dashboard");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  var totalCal = 0;
  var totalCalMix = 0;
  var totalSum = 0;

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Cart</title>
      </Helmet>
      {load ? (
        <div
          className={classes.container}
          style={{ border: "1px solid #000000" }}
        >
          <BillHeader />
          <ClientDetails />
          <Table
            tableHead={[
              <span className={classes.tableHead} key="sr">
                Sr.
                {/* <br /> */}
                No.
              </span>,
              "",
              <span className={classes.tableHead} key="des">
                Description of Goods
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="ctn"
              >
                Carton
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="qty"
              >
                Quantity
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="pri"
              >
                Price
              </span>,
              <span
                className={classes.tableHead + " " + classes.headRight}
                key="amt"
              >
                Amount
              </span>,
              // "",
            ]}
            tableData={[
              ...addCart.map((c, index) => [
                <span className={classes.listNumber} key={c.id}>
                  {index + 1}
                </span>,
                <div className={classe.imgContainer} key={c.id}>
                  {/* Date: {c.createDate.slice(0, -19)} */}
                  {"Product No. " + c.pId}
                  <img
                    src={
                      c.cartImage[0].cropImageUrl +
                      "?id=" +
                      c.cartImage[0].imgId
                    }
                    className={classes.img}
                    style={{ height: "140px" }}
                  />
                </div>,
                <span key={c.id}>
                  <small className={classes.listDescriptions}>
                    {c.cartChild.map((f) =>
                      f.title === "Plating Gold Plated" ? f.title.slice(8) : ""
                    )}{" "}
                    {c.cartParent.title} {c.cartRawPrice.title} <br />
                    {c.cartCategory.title} with {c.cartPacking.type}
                    <br />
                    {c.readyGram * c.cartSets.pcs} Avg Gram of{" "}
                    {c.cartSets.setDesc}
                    <br />
                    Sets Price :{" "}
                    {c.gstCash === false
                      ? (c.cashPrice / c.cartBox.box).toFixed(2)
                      : (c.taxInclude / c.cartBox.box).toFixed(2)}
                    /- {c.gstCash === false ? "Cash" : "IncTax"}
                    <br />
                    Metal Rate : ₹{" "}
                    {c.cartRawPrice.price + c.cartRawPrice.different}/- Advance
                    : ₹
                    {Math.round(
                      c.metalGram *
                        c.cartSets.pcs *
                        c.cartBox.box *
                        c.productSize.grandTotal *
                        (c.cartRawPrice.price + c.cartRawPrice.different)
                    )}
                  </small>
                  <br />

                  <GridContainer className={classes.sizePadding}>
                    {c.productSize.s1x8 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x8 == null
                          ? ""
                          : c.productSize.s1x8 == 0
                          ? ""
                          : "1x8"}
                        <br />
                        {c.productSize.s1x8 == null
                          ? ""
                          : c.productSize.s1x8 == 0
                          ? ""
                          : c.productSize.s1x8}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x10 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x10 == null
                          ? ""
                          : c.productSize.s1x10 == 0
                          ? ""
                          : "1x10"}
                        <br />
                        {c.productSize.s1x10 == null
                          ? ""
                          : c.productSize.s1x10 == 0
                          ? ""
                          : c.productSize.s1x10}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x12 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x12 == null
                          ? ""
                          : c.productSize.s1x12 == 0
                          ? ""
                          : "1x12"}
                        <br />
                        {c.productSize.s1x12 == null
                          ? ""
                          : c.productSize.s1x12 == 0
                          ? ""
                          : c.productSize.s1x12}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x14 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x14 == null
                          ? ""
                          : c.productSize.s1x14 == 0
                          ? ""
                          : "1x14"}
                        <br />
                        {c.productSize.s1x14 == null
                          ? ""
                          : c.productSize.s1x14 == 0
                          ? ""
                          : c.productSize.s1x14}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x0 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x0 == null
                          ? ""
                          : c.productSize.s2x0 == 0
                          ? ""
                          : "2x0"}
                        <br />
                        {c.productSize.s2x0 == null
                          ? ""
                          : c.productSize.s2x0 == 0
                          ? ""
                          : c.productSize.s2x0}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x2 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x2 == null
                          ? ""
                          : c.productSize.s2x2 == 0
                          ? ""
                          : "2x2"}
                        <br />
                        {c.productSize.s2x2 == null
                          ? ""
                          : c.productSize.s2x2 == 0
                          ? ""
                          : c.productSize.s2x2}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x4 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x4 == null
                          ? ""
                          : c.productSize.s2x4 == 0
                          ? ""
                          : "2x4"}
                        <br />
                        {c.productSize.s2x4 == null
                          ? ""
                          : c.productSize.s2x4 == 0
                          ? ""
                          : c.productSize.s2x4}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x6 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x6 == null
                          ? ""
                          : c.productSize.s2x6 == 0
                          ? ""
                          : "2x6"}
                        <br />
                        {c.productSize.s2x6 == null
                          ? ""
                          : c.productSize.s2x6 == 0
                          ? ""
                          : c.productSize.s2x6}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x8 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x8 == null
                          ? ""
                          : c.productSize.s2x8 == 0
                          ? ""
                          : "2x8"}
                        <br />
                        {c.productSize.s2x8 == null
                          ? ""
                          : c.productSize.s2x8 == 0
                          ? ""
                          : c.productSize.s2x8}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x10 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x10 == null
                          ? ""
                          : c.productSize.s2x10 == 0
                          ? ""
                          : "2x10"}
                        <br />
                        {c.productSize.s2x10 == null
                          ? ""
                          : c.productSize.s2x10 == 0
                          ? ""
                          : c.productSize.s2x10}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x12 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x12 == null
                          ? ""
                          : c.productSize.s2x12 == 0
                          ? ""
                          : "2x12"}
                        <br />
                        {c.productSize.s2x12 == null
                          ? ""
                          : c.productSize.s2x12 == 0
                          ? ""
                          : c.productSize.s2x12}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x14 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x14 == null
                          ? ""
                          : c.productSize.s2x14 == 0
                          ? ""
                          : "2x14"}
                        <br />
                        {c.productSize.s2x14 == null
                          ? ""
                          : c.productSize.s2x14 == 0
                          ? ""
                          : c.productSize.s2x14}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s3x0 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s3x0 == null
                          ? ""
                          : c.productSize.s3x0 == 0
                          ? ""
                          : "3x0"}
                        <br />
                        {c.productSize.s3x0 == null
                          ? ""
                          : c.productSize.s3x0 == 0
                          ? ""
                          : c.productSize.s3x0}
                      </span>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                  <GridContainer className={classes.sizePadding}>
                    {c.productSize.s1x9 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x9 == null
                          ? ""
                          : c.productSize.s1x9 == 0
                          ? ""
                          : "1x9"}
                        <br />
                        {c.productSize.s1x9 == null
                          ? ""
                          : c.productSize.s1x9 == 0
                          ? ""
                          : c.productSize.s1x9}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x11 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x11 == null
                          ? ""
                          : c.productSize.s1x11 == 0
                          ? ""
                          : "1x11"}
                        <br />
                        {c.productSize.s1x11 == null
                          ? ""
                          : c.productSize.s1x11 == 0
                          ? ""
                          : c.productSize.s1x11}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x13 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x13 == null
                          ? ""
                          : c.productSize.s1x13 == 0
                          ? ""
                          : "1x13"}
                        <br />
                        {c.productSize.s1x13 == null
                          ? ""
                          : c.productSize.s1x13 == 0
                          ? ""
                          : c.productSize.s1x13}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s1x15 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s1x15 == null
                          ? ""
                          : c.productSize.s1x15 == 0
                          ? ""
                          : "1x15"}
                        <br />
                        {c.productSize.s1x15 == null
                          ? ""
                          : c.productSize.s1x15 == 0
                          ? ""
                          : c.productSize.s1x15}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x1 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x1 == null
                          ? ""
                          : c.productSize.s2x1 == 0
                          ? ""
                          : "2x1"}
                        <br />
                        {c.productSize.s2x1 == null
                          ? ""
                          : c.productSize.s2x1 == 0
                          ? ""
                          : c.productSize.s2x1}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x3 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x3 == null
                          ? ""
                          : c.productSize.s2x3 == 0
                          ? ""
                          : "2x3"}
                        <br />
                        {c.productSize.s2x3 == null
                          ? ""
                          : c.productSize.s2x3 == 0
                          ? ""
                          : c.productSize.s2x3}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x5 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x5 == null
                          ? ""
                          : c.productSize.s2x5 == 0
                          ? ""
                          : "2x5"}
                        <br />
                        {c.productSize.s2x5 == null
                          ? ""
                          : c.productSize.s2x5 == 0
                          ? ""
                          : c.productSize.s2x5}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x7 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x7 == null
                          ? ""
                          : c.productSize.s2x7 == 0
                          ? ""
                          : "2x7"}
                        <br />
                        {c.productSize.s2x7 == null
                          ? ""
                          : c.productSize.s2x7 == 0
                          ? ""
                          : c.productSize.s2x7}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x9 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x9 == null
                          ? ""
                          : c.productSize.s2x9 == 0
                          ? ""
                          : "2x9"}
                        <br />
                        {c.productSize.s2x9 == null
                          ? ""
                          : c.productSize.s2x9 == 0
                          ? ""
                          : c.productSize.s2x9}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x11 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x11 == null
                          ? ""
                          : c.productSize.s2x11 == 0
                          ? ""
                          : "2x11"}
                        <br />
                        {c.productSize.s2x11 == null
                          ? ""
                          : c.productSize.s2x11 == 0
                          ? ""
                          : c.productSize.s2x11}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x13 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x13 == null
                          ? ""
                          : c.productSize.s2x13 == 0
                          ? ""
                          : "2x13"}
                        <br />
                        {c.productSize.s2x13 == null
                          ? ""
                          : c.productSize.s2x13 == 0
                          ? ""
                          : c.productSize.s2x13}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s2x15 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s2x15 == null
                          ? ""
                          : c.productSize.s2x15 == 0
                          ? ""
                          : "2x15"}
                        <br />
                        {c.productSize.s2x15 == null
                          ? ""
                          : c.productSize.s2x15 == 0
                          ? ""
                          : c.productSize.s2x15}
                      </span>
                    ) : (
                      ""
                    )}
                    {c.productSize.s3x1 != 0 ? (
                      <span
                        className={
                          classes.textCenter +
                          " " +
                          classes.sizeSpan +
                          " " +
                          classes.listDescriptionMM
                        }
                      >
                        {c.productSize.s3x1 == null
                          ? ""
                          : c.productSize.s3x1 == 0
                          ? ""
                          : "3x1"}
                        <br />
                        {c.productSize.s3x1 == null
                          ? ""
                          : c.productSize.s3x1 == 0
                          ? ""
                          : c.productSize.s3x1}
                      </span>
                    ) : (
                      ""
                    )}
                  </GridContainer>
                  {/* <span className={classes.listDescription}>
                  {c && c.name ? c.name + ", " : ""}{" "}
                  {c && c.mycomment ? c.mycomment : ""}
                </span> */}
                </span>,
                <span
                  className={classes.headRight + " " + classes.listNumber}
                  key={c.id}
                >
                  {c.cartQuantity}
                </span>,
                <span
                  className={classes.headRight + " " + classes.listNumber}
                  key={c.id}
                >
                  {c.productSize.grandTotal}
                </span>,
                <span
                  className={classes.headRight + " " + classes.listNumber}
                  key={c.id}
                >
                  ₹{" "}
                  {c.gstCash === true
                    ? c.taxInclude.toFixed(2)
                    : c.cashPrice.toFixed(2)}
                </span>,
                <span
                  className={classes.headRight + " " + classes.listNumber}
                  key={c.id}
                >
                  ₹ {Math.round(c.amount)}
                </span>,
                // <Tooltip
                //   id="close1"
                //   title="Cart Remove"
                //   placement="left"
                //   classes={{ tooltip: classe.tooltip }}
                //   key={c.id}
                // >
                //   <Button
                //     link
                //     className={classe.actionButton}
                //     onClick={() => handleitemRemove(c.id)}
                //   >
                //     <Close style={{ color: "red" }} />
                //   </Button>
                // </Tooltip>,
              ]),
              [
                "",
                <span
                  className={classes.headLeft + " " + classes.listNumber}
                  key={1}
                >
                  Cartoon Charges
                  <br />
                  Grand Total
                </span>,
                <span
                  className={classes.headRight + " " + classes.listNumber}
                  key={2}
                >
                  <br />
                  Advance - ₹{" "}
                  {Math.round(
                    addCart.reduce(
                      (a, b) =>
                        a +
                        b.metalGram *
                          b.cartSets.pcs *
                          b.cartBox.box *
                          b.productSize.grandTotal *
                          (b.cartRawPrice.price + b.cartRawPrice.different),
                      0
                    )
                  ) + "/-"}
                </span>,
                <span key={3}>
                  <br />
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                  >
                    Carton-
                    {addCart.reduce((a, b) => a + b.cartQuantity, 0)}
                  </span>
                </span>,
                <span>
                  <br />
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                  >
                    Unit-
                    {addCart &&
                      addCart.reduce((a, b) => a + b.productSize.grandTotal, 0)}
                  </span>
                </span>,
                "",
                <span key={4}>
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                  >
                    ₹
                    {addCart.reduce(
                      (a, b) => a + b.cartPackCharge * b.cartQuantity,
                      0
                    ) + "/-"}
                  </span>
                  <br />
                  <span
                    className={classes.headRight + " " + classes.listNumber}
                  >
                    ₹
                    {Math.round(
                      addCart.reduce(
                        (a, b) =>
                          a + b.amount + b.cartPackCharge * b.cartQuantity,
                        0
                      )
                    ) + "/-"}
                  </span>
                </span>,
                // "",
              ],
            ]}
          />
          <hr />
          <GridItem className={classes.textCenter}>
            <h5>
              <strong style={{ textDecoration: "underline" }}>
                Terms & Condition
              </strong>
            </h5>
          </GridItem>
          <GridContainer className={classes.condition}>
            <GridItem xs={12} sm={12} md={12}>
              <ol style={{fontSize: "12px"}}>
                <li>
                  The order will be delivered within 25 working days in India
                  from the date of payment and it will take minimum 90
                  working days to be delivered outside India.
                </li>
                <li>
                  Advance{" "}
                  <span className={classes.termDescription}>
                    Rs. ₹{" "}
                    {Math.round(
                      addCart.reduce(
                        (a, b) =>
                          a +
                          b.metalGram *
                            b.cartSets.pcs *
                            b.cartBox.box *
                            b.productSize.grandTotal *
                            (b.cartRawPrice.price + b.cartRawPrice.different),
                        0
                      )
                    ) + "/-"}
                  </span>{" "}
                  payment will have to be made for the metal at the time of
                  order.{" "}
                </li>
                <li>
                  When the goods will be ready. The making charges will have to
                  be paid for the goods being dispatched on the first shipment.
                  After that, when the second shipment takes place, its making
                  charges will have to be paid.
                </li>
                <li>There will be two shipments of goods.</li>
                <li>Packing charges and transport charges will be extra.</li>
                <li>
                  If you want to cover the insurance, then 3% extra will have to
                  be paid on the purchase amount.
                </li>
                <li>
                  Proposal is valid till{" "}
                  {/* <span className={classes.termDescription}>
                    {updateDate}
                  </span>{" "} */}
                  from,
                </li>
              </ol>
            </GridItem>
          </GridContainer>

          <div className={classes.brand}>
            <Button
              className={classes.print}
              color="info"
              round
              onClick={() => window.print()}
            >
              Print
            </Button>
            <span style={{ marginLeft: "10px" }}>
              <Button
                className={classes.print}
                color="info"
                round
                onClick={() => setLoginModal(true)}
              >
                Proceed Order
              </Button>
            </span>
          </div>
        </div>
      ) : (
        ""
      )}

      <GridItem xs={12} sm={6} md={6} lg={6}>
        <Dialog
          classes={{
            root: classPop.modalRoot,
            paper: classPop.modal + " " + classPop.modalLogin,
          }}
          open={loginModal}
          TransitionComponent={Transition}
          keepMounted
          // onClose={() => setLoginModal(false)}
          aria-labelledby="login-modal-slide-title"
          aria-describedby="login-modal-slide-description"
        >
          <Card plain className={classPop.modalLoginCard}>
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={classPop.modalHeader}
            >
              <CardHeader
                plain
                color="info"
                className={classPop.textCenter + " " + classPop.cardLoginHeader}
              >
                <Button
                  simple
                  className={classPop.modalCloseButton}
                  key="close"
                  aria-label="Close"
                  onClick={() => setLoginModal(false)}
                >
                  {" "}
                  <Close className={classPop.modalClose} />
                </Button>
                <h5 className={classPop.cardTitleWhite}>Log in</h5>
                <div className={classPop.socialLine}>
                  <Button justIcon link className={classPop.socialLineButton}>
                    <i className="fab fa-facebook-square" />
                  </Button>
                  <Button justIcon link className={classPop.socialLineButton}>
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button justIcon link className={classPop.socialLineButton}>
                    <i className="fab fa-google-plus-g" />
                  </Button>
                </div>
              </CardHeader>
            </DialogTitle>
            <DialogContent
              id="login-modal-slide-description"
              className={classPop.modalBody}
            >
              {!otpScreen ? (
                <form>
                  <p
                    className={classPop.description + " " + classPop.textCenter}
                  >
                    Welcome
                  </p>
                  <CardBody className={classPop.cardLoginBody}>
                    <GridContainer justify="center">
                      {/* <GridItem xs={12} sm={12} md={12}> */}
                      <NavPills
                        color="info"
                        alignCenter
                        tabs={[
                          {
                            tabButton: "Mobile",
                            tabIcon: PhoneAndroidIcon,
                            tabContent: (
                              <>
                                {/* <CardBody> */}
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <PhoneInput
                                      inputStyle={{
                                        width: "100%",
                                        border: "none",
                                        borderBottom: "1px solid #040000 ",
                                        borderRadius: "0",
                                      }}
                                      country={"in"}
                                      name="mobile"
                                      value={mobile}
                                      onChange={(mobile) => {
                                        handleMobileNumber(mobile);
                                      }}
                                      required={true}
                                    />
                                    <span style={{ color: "red" }}>
                                      {mobileErr}
                                    </span>
                                  </GridItem>
                                  <Button
                                    color="info"
                                    simple
                                    // size="lg"
                                    block
                                    onClick={handleSendOtpWithMobile}
                                  >
                                    Login
                                  </Button>
                                </GridContainer>
                                {/* </CardBody> */}
                                {/* <CardFooter
                                  className={classe.justifyContentCenter}
                                >
                                  <Button
                                    color="info"
                                    simple
                                    // size="lg"
                                    block
                                    onClick={handleSendOtpWithMobile}
                                  >
                                    Login
                                  </Button>
                                </CardFooter> */}
                              </>
                            ),
                          },
                          {
                            tabButton: "Email",
                            tabIcon: EmailIcon,
                            tabContent: (
                              <>
                                {/* <CardBody> */}
                                <GridContainer>
                                  <GridItem xs={12} sm={12} md={12}>
                                    <CustomInput
                                      formControlProps={{
                                        fullWidth: true,
                                        className:
                                          classes.customFormControlClasses,
                                      }}
                                      inputProps={{
                                        onChange: (e) => handleEmail(e),
                                        id: "email",
                                        value: email,
                                        name: "email",
                                        placeholder: "Email...",
                                      }}
                                      required={true}
                                    />
                                    <span style={{ color: "red" }}>
                                      {emailErr}
                                    </span>
                                  </GridItem>
                                  <Button
                                    color="info"
                                    simple
                                    // size="lg"
                                    block
                                    onClick={handleSendOtpWithEmail}
                                  >
                                    Login
                                  </Button>
                                </GridContainer>
                                {/* </CardBody> */}
                                {/* <CardFooter
                                  className={classe.justifyContentCenter}
                                >
                                  <Button
                                    color="info"
                                    simple
                                    // size="lg"
                                    block
                                    onClick={handleSendOtpWithEmail}
                                  >
                                    Login
                                  </Button>
                                </CardFooter> */}
                              </>
                            ),
                          },
                        ]}
                      />
                      {/* </GridItem> */}
                    </GridContainer>
                  </CardBody>
                  <br />
                  <div className={classes.textCenter}>
                    <Button
                      simple
                      color="info"
                      // size="lg"
                      href="/bangles-sign-up"
                    >
                      Create New Account
                    </Button>
                  </div>
                </form>
              ) : (
                <form>
                  <p
                    className={classPop.description + " " + classPop.textCenter}
                  >
                    6 digit OTP SMS sent on your mobile
                  </p>
                  <p
                    className={classPop.description + " " + classPop.textCenter}
                  >
                    {otp}
                  </p>
                  {/* <p
                    className={
                      classPop.description + " " + classPop.textCenter
                    }
                  >
                    {reSend}
                  </p> */}
                  <CardBody className={classPop.cardLoginBody}>
                    <CustomInput
                      id="otpNumber"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        name: "otpNumber",
                        value: otpNumber,
                        onChange: (e) => handleOtp(e),
                        placeholder: "Enter OTP here",
                        type: "text",
                        // endAdornment: (
                        //   <InputAdornment position="end">
                        //     <Icon className={classes.inputIconsColor}>
                        //       lock_utline
                        //     </Icon>
                        //   </InputAdornment>
                        // ),
                        autoComplete: "off",
                      }}
                      required={true}
                    />
                    <p
                      className={classes.description + " " + classes.textCenter}
                      style={{ color: "red" }}
                    >
                      {otpNumberErr}
                    </p>
                  </CardBody>
                  <div className={classes.textCenter}>
                    <Button
                      simple
                      color="info"
                      // size="lg"
                      onClick={
                        mobile ? handleLoginWithMobile : handleLoginWithEmail
                      }
                    >
                      Log in
                    </Button>
                    <Button
                      simple
                      color="info"
                      // size="lg"
                      // onClick={handleCall}
                    >
                      <FiPhoneCall />
                      Call Me
                    </Button>
                  </div>
                  <p className={classes.description + " " + classes.textCenter}>
                    {" "}
                    Didn't receive the OTP yet?{" "}
                    <span style={{ marginLeft: "10px" }}>
                      <a
                        href="#"
                        onClick={
                          mobile
                            ? handleSendOtpWithMobile
                            : handleSendOtpWithEmail
                        }
                      >
                        Resend OTP
                      </a>
                    </span>
                  </p>
                </form>
              )}
            </DialogContent>
          </Card>
        </Dialog>
      </GridItem>
    </div>
  );
}
