/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
import classNames from "classnames";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi.js";
import { makeStyles } from "@material-ui/core/styles";
import Favorite from "@material-ui/icons/Favorite";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import { TiArrowForward } from "react-icons/ti";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import CornerRibbon from "react-corner-ribbon";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import BanglesHeader from "./Section/BanglesHeader.js";
import HeaderLink from "./Section/BanglesHeaderLink.js";
import BanglesFooter from "./Section/BanglesFooter.js";
import logo from "assets/img/examples/apple-bangles-org-icon.png";
import kada1 from "assets/img/faces/kada1.jpg";
import polaBangles2 from "assets/img/faces/polaBangles2.jpg";
import fourPieces3 from "assets/img/faces/fourPieces3.jpg";
import sixPieces4 from "assets/img/faces/sixPieces4.jpg";
import sevenPieces5 from "assets/img/faces/sevenPieces5.jpg";
import eightPieces6 from "assets/img/faces/eightPieces6.jpg";
import twevelPieces7 from "assets/img/faces/twevelPieces7.jpg";
import twentyFourPieces8 from "assets/img/faces/twentyFourPieces8.jpg";
import axios from "axios";

const useStyles = makeStyles(productStyle);
const useStyle = makeStyles(aboutUsStyle);
const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function BanglesProductList() {
  const [prodList, setProdList] = React.useState([]);
  const [page, setpage] = React.useState(0);
  const [loginModal, setLoginModal] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classPop = useStylPop();

  const activeSession = sessionStorage.getItem("sessionData");
  // const headApi = `https://api.bangles.org.in/api/rest`;
  // const imgApi = `https://imgstore.bangles.org.in`;

  const fetchData = async () => {
    // console.log(page + 1);
    setpage(page + 1);
    axios
      .get(
        localApi +
        `/product/list/byLimitActive?adminId=2&active=1&page=${page}&size=4`
      )
      .then((res) => setProdList([...prodList, ...res.data]));
  };

  useEffect(() => {
    fetchData();
    setTimeout(hideAlertPop, 2000);
    setLoginModal(true);
  }, []);

  const hideAlertPop = () => {
    setLoginModal(false);
  };

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Product List</title>
        <meta
          name="description"
          content="We make bangles with good quality raw material at factory prices.Bangles such as Gold Polished bangles, Die gold plated bangles, silver bangles, Micro Gold plated, rose gold plated bangles, 3tone bangles, Diamond Dell bangles, Rhodium bangles.We are basically working on customize designs and customise orders.you can get any sizes,colors, models and designs,we can make the bangles according to your requirements and needs. We are providing Zero making charges on bulk quantity orders (minimum purchase of 10 cartons)."
        />
        <meta
          name="keywords"
          content="fancy bangles, party wear bangles for women, stylish gold plated bangles, trendy gold bangles, imitation bangles, designer bangles, brass gold plated bangles, casual everyday bangles, elegant and beautiful bangles, finely polished, unique design angles,charming bangles, catchy designs"
        />
      </Helmet>
      <BanglesHeader
        brand="Home"
        links={<HeaderLink dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "info",
        }}
      />
      <Parallax
        // image={require("assets/img/bg6.jpg").default}
        filter="dark"
        className={classes.pageHeader}
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 className={classe.title}>Product List</h1>
              <h4 style={{ color: "white" }}>
                Bangles.Org products are light-weight , elegant and
                sophisticated - the perfect fit for today{"'"}s modern women
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classes.container}>
        <h5 className={classNames(classes.title, classes.textCenter)}>
          We make bangles that are loved by all age group ladies because our
          designs are unique and never get out of fashion. The raw material we
          use is of fine quality and also we give 6 month colour guarantee on
          our bangles. As we are a manufacturer, we give bangles at factory
          prices (discounted rates). We give you an opportunity to customize the
          bangles according to your choice and taste so that you feel the joy of
          wearing the best bangles.
        </h5>
      </div>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <Dialog
            style={{ marginTop: "10%" }}
            classes={{
              root: classPop.modalRoot,
              paper: classPop.modal + " " + classPop.modalLogin,
            }}
            open={loginModal}
            TransitionComponent={Transition}
            keepMounted
            // onClose={() => setLoginModal(false)}
            aria-labelledby="login-modal-slide-title"
            aria-describedby="login-modal-slide-description"
          >
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={classPop.modalHeader}
            >
              <h5>Loading...</h5>
              <DialogContent
                id="login-modal-slide-description"
                className={classPop.modalBody}
              >
                <GridContainer justify="center">
                  <Spinner
                    onChange={() => hideAlertPop()}
                    style={{ color: "#00acc1" }}
                  />
                </GridContainer>
                <br />
              </DialogContent>
            </DialogTitle>
          </Dialog>
          <div className={classes.relatedProducts}>
            <GridContainer>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={kada1} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${kada1})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={polaBangles2} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${polaBangles2})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={fourPieces3} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${fourPieces3})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={sixPieces4} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${sixPieces4})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={sevenPieces5} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${sevenPieces5})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={eightPieces6} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${eightPieces6})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>

              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={twevelPieces7} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${twevelPieces7})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                <Card profile plain className={classes.card3}>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <CardHeader image plain>
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img src={twentyFourPieces8} alt="..." />
                          <p
                            style={{
                              position: "absolute",
                              top: "80%",
                              left: "50%",
                              width: "40px",
                              height: "40px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                          </p>
                        </a>
                        <div
                          className={classes.coloredShadow}
                          style={{
                            backgroundImage: `url(${twentyFourPieces8})`,
                            opacity: "1",
                          }}
                        />
                      </CardHeader>
                    </GridItem>
                  </GridContainer>
                </Card>
              </GridItem>
            </GridContainer>
            <h3 className={classNames(classes.title, classes.textCenter)}>
              Popular items Today:
            </h3>

            <InfiniteScroll
              dataLength={prodList.length}
              next={fetchData}
              hasMore={true}
            >
              <div className={classes.container}>
                <GridContainer>
                  {prodList &&
                    prodList.map((prod) => (
                      <GridItem sm={6} md={3} key={prod.id}>
                        <Card product>
                          <CardHeader image>
                            {/* <CornerRibbon
                          position={prod.total > 0 ? "top-left" : "top-right"} // position="top-right" // OPTIONAL, default as "top-right"
                          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                          backgroundColor="#00acc1" // OPTIONAL, default as "#2c7"
                          containerStyle={{}} // OPTIONAL, style of the ribbon
                          style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                          className="" // OPTIONAL, css class of ribbon
                        >
                          {" "}
                          {prod.total > 0 ? "In Stock" : "Make To Order"}
                        </CornerRibbon> */}
                            <a
                              href={`/bangles-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                            // &Plating=${prod.plating.description.slice(8)}
                            >
                              {/* <img src={cardProduct1} alt="cardProduct" /> */}
                              {/* {prod.design1.img1active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design1.image1}
                              alt="..."
                            />
                          ) : prod.design2.img2active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design2.image2}
                              alt="..."
                            />
                          ) : prod.design3.img3active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design3.image3}
                              alt="..."
                            />
                          ) : prod.design4.img4active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design4.image4}
                              alt="..."
                            />
                          ) : prod.design5.img5active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design5.image5}
                              alt="..."
                            />
                          ) : prod.design6.img6active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design6.image6}
                              alt="..."
                            />
                          ) : (
                            ""
                          )} */}
                              <img
                                src={
                                  prod.productImage[0].cropImageUrl +
                                  "?id=" +
                                  prod.productImage[0].imgId
                                }
                                alt="..."
                              />
                              <p
                                style={{
                                  position: "absolute",
                                  top: "65%",
                                  left: "50%",
                                  width: "65px",
                                  height: "65px",
                                  transform: "translate(-50%, -50%)",
                                }}
                              >
                                <img src={logo} alt="..." />
                                {/* {imgApi + "" + prod.design1.image1 !==
                            imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design2.image2 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design3.image3 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design4.image4 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design5.image5 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design6.image6 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : (
                              ""
                            )} */}
                              </p>
                            </a>
                          </CardHeader>
                          <CardBody>
                            <h6
                              className={classNames(
                                classes.cardCategory,
                                classes.textRose
                              )}
                            >
                              {prod.productCategory.title +
                                " " +
                                prod.productParent.title}{" "}
                              <br /> {prod.itemCodeNumber}-{prod.id}
                            </h6>
                            <h4 className={classes.cardTitle}>
                              {prod.prodDescription}
                            </h4>
                            <div className={classes.cardDescription}>
                              {(prod.readyGram * prod.productSets.pcs).toFixed(
                                4
                              )}{" "}
                              Avg Gram Of {prod.productSets.setDesc} {"and "}
                              {prod.productBox.boxDesc}
                              {","} {prod.cartDescription}
                              {"."}
                            </div>
                          </CardBody>
                          <CardFooter className={classes.justifyContentBetween}>
                            <div className={classes.price}>
                              {prod.gstCash === true ? (
                                <>
                                  <h5>
                                    <span style={{ color: "red" }}>
                                      Price ₹ {(prod.taxInclude / prod.productBox.box).toFixed(2) + " Inc."}
                                    </span>
                                  </h5>
                                </>
                              ) : (
                                <>
                                  <h5>
                                    <span style={{ color: "black" }}>
                                      Price ₹ {(prod.cashPrice / prod.productBox.box).toFixed(2)}
                                    </span>
                                  </h5>
                                </>
                              )}
                              {/* {activeSession ? "" : prod.taxCalculation.profitactive === 1 ? <h5>Price <span style={{ color: "red" }}>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> : <h5>Price <span>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-*</span></h5>}

                                                {!activeSession ? <h6><a href="/bangles-login">Login</a> to see prices and exciting offers.
                                                </h6> : prod.taxCalculation.profitactive === 1 ? <h6>Price <span style={{ textDecoration: "line-through", color: "red" }}>₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span>{" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6> :
                                                    <h6>Price <span style={{ textDecoration: "line-through" }}>₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span> {" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6>
                                                } */}

                              {/* {!activeSession ? (
                            prod.taxCalculation.profitactive === 1 ? (
                              prod.taxCalculation.cash_gst === 1 ? (
                                <>
                                  <h5>
                                    <span style={{ color: "red" }}>
                                      Price ₹{" "}
                                      {(
                                        prod.taxCalculation.taxincl /
                                        prod.box.box
                                      ).toFixed(2)}
                                      /-
                                    </span>
                                  </h5>{" "}
                                  <h6>
                                    <a href="/bangles-login">Login</a> to see
                                    prices and exciting offers.
                                  </h6>{" "}
                                </>
                              ) : (
                                <h5>
                                  Price{" "}
                                  <span>
                                    ₹{" "}
                                    {(
                                      prod.taxCalculation.cash / prod.box.box
                                    ).toFixed(2)}
                                    /-*
                                  </span>
                                </h5>
                              )
                            ) : prod.taxCalculation.cash_gst === 1 ? (
                              <>
                                <h5>
                                  <span style={{ color: "#9c27b0" }}>
                                    Price ₹{" "}
                                    {(
                                      prod.taxCalculation.taxincl / prod.box.box
                                    ).toFixed(2)}
                                    /-
                                  </span>
                                </h5>{" "}
                                <h6>
                                  <a href="/bangles-login">Login</a> to see
                                  prices and exciting offers.
                                </h6>
                              </>
                            ) : (
                              <h5>
                                Price{" "}
                                <span>
                                  ₹{" "}
                                  {(
                                    prod.taxCalculation.cash / prod.box.box
                                  ).toFixed(2)}
                                  /-*
                                </span>
                              </h5>
                            )
                          ) : (
                            ""
                          )}

                          {activeSession ? (
                            prod.taxCalculation.profitactive === 1 ? (
                              <h6>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "red",
                                  }}
                                >
                                  Price ₹
                                  {(
                                    prod.taxCalculation.taxincl / prod.box.box
                                  ).toFixed(2)}
                                  /
                                </span>{" "}
                                ₹{" "}
                                {(
                                  prod.taxCalculation.cash / prod.box.box
                                ).toFixed(2)}
                                /-*
                              </h6>
                            ) : (
                              <h6>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "#9c27b0",
                                  }}
                                >
                                  Price ₹
                                  {(
                                    prod.taxCalculation.taxincl / prod.box.box
                                  ).toFixed(2)}
                                  /
                                </span>{" "}
                                ₹{" "}
                                {(
                                  prod.taxCalculation.cash / prod.box.box
                                ).toFixed(2)}
                                /-*
                              </h6>
                            )
                          ) : (
                            ""
                          )} */}
                            </div>
                            <div className={classes.stats}>
                              <Tooltip
                                id="tooltip-top"
                                title="Save to Wishlist"
                                placement="top"
                                classes={{ tooltip: classes.tooltip }}
                              >
                                <Button justIcon color="rose" simple>
                                  <Favorite />
                                </Button>
                              </Tooltip>
                            </div>
                          </CardFooter>
                          <div style={{ textAlign: "center", marginBottom: "7px" }}>
                            <Button
                              color="info"
                              href={`/bangles-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                            // &Plating=${prod.plating.description.slice(8)}
                            >
                              {/* <Subject /> */}
                              Buy Now
                            </Button>
                          </div>
                        </Card>
                      </GridItem>
                    ))}
                </GridContainer>
              </div>
            </InfiniteScroll>
          </div>
          <GridItem
            md={3}
            sm={3}
            className={classNames(classes.mlAuto, classes.mrAuto)}
          >
            <Button color="info" round>
              Loading...
            </Button>
          </GridItem>
        </div>
      </div>
      <BanglesFooter />
    </div>
  );
}
