import React from "react";
import classNames from "classnames";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";
import { SiWhatsapp } from "react-icons/si";
import { MdEmail } from "react-icons/md";
import { ImMobile } from "react-icons/im";
import { Link } from "@material-ui/core";

import descriptionStyle from "assets/jss/material-kit-pro-react/views/aboutUsSections/descriptionStyle.js";

const useStyles = makeStyles(descriptionStyle);

export default function RefundDescription() {
  const classes = useStyles();
  return (
    <div className={classNames(classes.aboutDescription)}>
      <GridContainer>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>REFUND POLICY</h3>
          <h4 style={{ textTransform: "uppercase" }}>Returns</h4>
          <h5 className={classes.description}>
            Our policy lasts 7 days. If 7 days have gone by since your purchase,
            unfortunately we can{"'"}t offer you a refund.
            <br />
            To be eligible for a return, your item must be according to the
            bill, unused and in the same condition that you received it. It must
            also be in the original packaging.
          </h5>
          <h4 style={{ textTransform: "uppercase" }}>
            Refunds (if applicable)
          </h4>
          <h5 className={classes.description}>
            Once your return is received and inspected, we will send you an
            email to notify you that we have received your returned item. We
            will also notify you of the approval or rejection of your refund. If
            you are approved, then your refund will be processed, and a credit
            will automatically be applied to your credit card or original method
            of payment, within a certain amount of days.
          </h5>
          <h4 style={{ textTransform: "uppercase" }}>
            Late or missing refunds (if applicable)
          </h4>
          <h5 className={classes.description}>
            If you haven{"'"}t received a refund yet, first check your bank
            account again. Then contact your credit card company, it may take
            some time before your refund is officially posted. Next contact your
            bank. There is often some processing time before a refund is posted.
            If you{"'"}ve done all of this and you still have not received your
            refund yet, please contact us at info@bangles.org.in
          </h5>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>
            HOW SOON WILL I GET MY REFUND?
          </h3>
          <ul>
            <li className={classes.description}>
              Once your return is received and inspected, we will send you an
              email to notify you that we have received your returned item. We
              will also notify you of the approval or rejection of your refund.
            </li>
            <li className={classes.description}>
              If you are approved, then your refund will be processed, and a
              credit will automatically be applied method of payment, within 7
              business days.
            </li>
          </ul>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>CANCELLATION</h3>
          <ul>
            <li className={classes.description}>
              We reserve the right to cancel your order in case of any
              discrepancy. But within 7 days.
            </li>
            <li className={classes.description}>
              If in case the amount is deducted from the customer's account and
              the transaction has failed, the same will be refunded back to your
              account.
            </li>
            <li className={classes.description}>
              KINDLY NOTE: if you are liable for a refund we request you to
              enter your account details in the {"'"}notes{"'"} section while
              submitting your return request. Refunds can only be done via Bank
              transfer and no cash refunds will be done.
            </li>
          </ul>
        </GridItem>
        <GridItem
          md={12}
          sm={12}
          className={classNames(classes.mrAuto, classes.mlAuto)}
        >
          <h3 style={{ textTransform: "uppercase" }}>ANY OTHER HELP?</h3>
          <h5 className={classes.description}>
            If you need help with something that is not mentioned above you can
            contact us in the following way:
            <br />
            Phone:{" "}
            <a href="tel:+91-9967427786">
              <SiWhatsapp color="green" /> 9967427786
            </a>
            /
            <a href="tel:+91-2247501787">
              <ImMobile /> 2247501787
            </a>
            <br />
            Email: i
            <Link
              to="javascript:void(0)"
              onClick={() => (window.location = "mailto:info@bangles.org.in")}
            >
              <span>
                <MdEmail /> info@bangles.org.in
              </span>
            </Link>
            <br />
          </h5>
        </GridItem>
      </GridContainer>
      <br />
    </div>
  );
}
