/* eslint-disable */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import { headApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { localApi } from "utils/headApi";
import ListItem from "@material-ui/core/ListItem";
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart";
import Search from "@material-ui/icons/Search";
import Apps from "@material-ui/icons/Apps";
import { FaUserEdit, FaExpeditedssl, FaAddressBook } from "react-icons/fa";
import { IoMdLogOut } from "react-icons/io";
import { RiShoppingBagLine } from "react-icons/ri";
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
// import { MdOutlineAddChart } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import CustomInput from "components/CustomInput/CustomInput.js";
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/headerLinksStyle.js";
import navbarsStyle from "assets/jss/material-kit-pro-react/views/componentsSections/navbarsStyle.js";

const useStyles = makeStyles(styles);
const useStyle = makeStyles(navbarsStyle);

export default function HeaderLinks(props) {
  const [bangle, setbangle] = React.useState([]);
  const [earing, setEaring] = React.useState([]);
  const [ring, setRing] = React.useState([]);
  const [watch, setWatch] = React.useState([]);
  const [kada, setKada] = React.useState([]);
  const [cardList, setCardList] = React.useState([]);
  const [cardStock, setCardStock] = React.useState([]);

  // const [addCart, setAddCart] = React.useState([])
  // const easeInOutQuad = (t, b, c, d) => {
  //   t /= d / 2;
  //   if (t < 1) return (c / 2) * t * t + b;
  //   t--;
  //   return (-c / 2) * (t * (t - 2) - 1) + b;
  // };

  // const smoothScroll = (e, target) => {
  //   if (window.location.pathname === "/sections") {
  //     var isMobile = navigator.userAgent.match(
  //       /(iPad)|(iPhone)|(iPod)|(android)|(webOS)/i
  //     );
  //     if (isMobile) {
  //       // if we are on mobile device the scroll into view will be managed by the browser
  //     } else {
  //       e.preventDefault();
  //       var targetScroll = document.getElementById(target);
  //       scrollGo(document.documentElement, targetScroll.offsetTop, 1250);
  //     }
  //   }
  // };
  // const scrollGo = (element, to, duration) => {
  //   var start = element.scrollTop,
  //     change = to - start,
  //     currentTime = 0,
  //     increment = 20;

  //   var animateScroll = function () {
  //     currentTime += increment;
  //     var val = easeInOutQuad(currentTime, start, change, duration);
  //     element.scrollTop = val;
  //     if (currentTime < duration) {
  //       setTimeout(animateScroll, increment);
  //     }
  //   };
  //   animateScroll();
  // };
  var onClickSections = {};

  const { dropdownHoverColor } = props;
  const classes = useStyles();
  const classe = useStyle();
  const history = useHistory();

  const date = Intl.DateTimeFormat("fr-CA", {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  })
    .format(new Date())
    .replaceAll("/", "-");

  const activeSession = sessionStorage.getItem("sessionData");

  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  // const headApi = `https://api.bangles.org.in/api/rest`;

  // async function getUsers() {
  //     const api = `https://secure.mybangles24.com/api/rest/categories`;
  //     const result = await fetch(api);
  //     const getResult = await result.json();
  //     // console.log(getResult.CategoriesData);
  //     setCategory(getResult.CategoriesData.filter(item => item.description !== "My Offer"));
  //     let catId = getResult.CategoriesData.find((d) => d?.id);
  //     const get = `https://www.secure.mybangles24.com/api/rest/categories/size-mm?catid=${catId.id}&userid=1`;
  //     const results = await fetch(get);
  //     const ress = await results.json();
  //     setCatList(ress.cat1SizemmData);
  // }

  // useEffect(() => {
  //     getUsers();
  // }, []);

  useEffect(() => {
    const fetchCartListData = async () => {
      try {
        const response = await fetch(localApi + `/clientGroup/allList`);
        const json = await response.json();
        const cList = json.ClientList.filter((f) => f.regId === userObj.id)[0];

        const list =
          localApi +
          `/cart/expireCart/byAdminAndClientIdAndAcitve/${userObj.admin.id}/${cList.id}?active=0`;
        const result = await fetch(list);
        const data = await result.json();
        setCardList(data);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchCartListData();
  }, []);

  useEffect(() => {
    const fetchCartStockData = async () => {
      try {
        const response = await fetch(localApi + `/clientGroup/allList`);
        const json = await response.json();
        const cList = json.ClientList.filter((f) => f.regId === userObj.id)[0];

        const listStock =
          localApi +
          `/cart/list/byAdminAndActiveAndStock?admin_id=${userObj.admin.id}&client_id=${cList.id}&active=0&stock=1`;
        const resultStock = await fetch(listStock);
        const dataStock = await resultStock.json();
        // console.log(dataStock);
        setCardStock(dataStock);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchCartStockData();
  }, []);

  useEffect(() => {
    const fetchBanglesData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byAdminIdAndCategoryId/2/11`
        );
        const json = await response.json();
        setbangle(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchBanglesData();
  }, []);

  useEffect(() => {
    const fetchEarRingsData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byAdminIdAndCategoryId/2/12`
        );
        const json = await response.json();
        setEaring(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchEarRingsData();
  }, []);

  useEffect(() => {
    const fetchFingerRingsData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byAdminIdAndCategoryId/2/13`
        );
        const json = await response.json();
        setRing(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchFingerRingsData();
  }, []);

  useEffect(() => {
    const fetchWatchData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byAdminIdAndCategoryId/2/14`
        );
        const json = await response.json();
        setWatch(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchWatchData();
  }, []);

  useEffect(() => {
    const fetchKadaData = async () => {
      try {
        const response = await fetch(
          localApi + `/parent/list/byAdminIdAndCategoryId/2/15`
        );
        const json = await response.json();
        setKada(json);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchKadaData();
  }, []);

  const handleLogOut = () => {
    const finalURL =
      localApi + `/otp/logout/registerById/${userObj && userObj.id}`;
    fetch(finalURL, {
      method: "PUT",
      // headers: {
      //   "content-type": "application/json",
      //   accept: "application/json",
      // },
    })
      .then((response) => response.json())
      .then((response) => {
        // console.log(response);
        if (response.error === true) {
          localStorage.removeItem("sessionData");
          sessionStorage.removeItem("sessionData");
          history.push("/");
          window.location.reload(false);
        }
      });
  };

  return (
    <>
      <List className={classes.list + " " + classes.mlAuto}>
        {/* {" "}
        <div className={classe.mlAuto}>
          <CustomInput
            // black

            white
            inputRootCustomClasses={classe.inputRootCustomClasses}
            formControlProps={{
              className: classe.formControl,
            }}
            inputProps={{
              placeholder: "Search",
              inputProps: {
                "aria-label": "Search",
                className: classe.searchInput,
              },
            }}
          />
          <Button color="white" justIcon round>
            <Search className={classe.searchIcon} />
          </Button>
        </div>{" "} */}
        {/* {category && category.map((cat) => (
                    <ListItem className={classes.listItem} key={cat.id}>
                        <CustomDropdown
                            noLiPadding
                            navDropdown
                            hoverColor={dropdownHoverColor}
                            buttonText={cat.description}
                            buttonProps={{
                                className: classes.navLink,
                                color: "transparent",
                            }}
                            buttonIcon={Apps}
                            dropdownList=
                        {catList && catList.map((x) => (
                        <Link to="/" className={classes.dropdownLink} key={x.id}>
                            {x.description}
                        </Link>
                        ))}
                        />
                    </ListItem>
                ))} */}
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Bangles"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              bangle &&
              bangle.map((x) => (
                // /bangles-size-mm-list?cat=bangles&size=102
                <Link
                  to={`/bangles-size-mm-list?catdesc=BANGLES&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Earings"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              earing &&
              earing.map((x) => (
                <Link
                  to={`/bangles-size-mm-list?catdesc=EARRINGS&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Finger Ring"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              ring &&
              ring.map((x) => (
                <Link
                  to={`/bangles-size-mm-list?catdesc=FINGER RINGS&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Watch"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              watch &&
              watch.map((x) => (
                <Link
                  to={`/bangles-size-mm-list?catdesc=WATCH&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>
        <ListItem className={classes.listItem}>
          <CustomDropdown
            noLiPadding
            navDropdown
            hoverColor={dropdownHoverColor}
            buttonText="Punjabi kada"
            buttonProps={{
              className: classes.navLink,
              color: "transparent",
            }}
            buttonIcon={Apps}
            dropdownList={
              kada &&
              kada.map((x) => (
                <Link
                  to={`/bangles-size-mm-list?catdesc=PUNJABI KADA&cat1desc=${x.title}&Plating=Gold%20Plating`}
                  className={classes.dropdownLink}
                  key={x.id}
                >
                  {x.title}
                </Link>
              ))
            }
          />
        </ListItem>
        {/* <ListItem className={classes.listItem}>
          <Button
            href="/todays-deal"
            className={classes.navLink}
            // onClick={(e) => e.preventDefault()}
            color="transparent"
          >
            Todays Deals
          </Button>
        </ListItem> */}
        {/* {cardStock && cardStock.length != 0 ? (
        <ListItem className={classes.listItem}>
          <Button
            href="/todays-deal"
            className={classes.navLink}
            // onClick={(e) => e.preventDefault()}
            color="transparent"
          >
            Todays Deals
          </Button>
        </ListItem>) : "" } */}
        {!activeSession ? (
          ""
        ) : (
          <span>
            {cardList && cardList.length != 0 ? (
              <ListItem className={classes.listItem}>
                <CustomDropdown
                  noLiPadding
                  navDropdown
                  hoverColor={dropdownHoverColor}
                  buttonText="Cart Count"
                  buttonProps={{
                    className: classes.navLink,
                    color: "transparent",
                  }}
                  buttonIcon={ShoppingCartIcon}
                  dropdownList={[
                    <Link
                      to="/add-cart"
                      // to={`/cart?byuserid=919967457786&clientid=${
                      //   userObj && userObj.mobile.slice(1)
                      // }`}
                      className={classes.dropdownLink}
                    >
                      <AiOutlineAppstoreAdd className={classes.dropdownIcons} />{" "}
                      My Cart
                    </Link>,
                  ]}
                />
              </ListItem>
            ) : (
              ""
            )}
            <ListItem className={classes.listItem}>
              <CustomDropdown
                noLiPadding
                navDropdown
                hoverColor={dropdownHoverColor}
                buttonText="Section"
                buttonProps={{
                  className: classes.navLink,
                  color: "transparent",
                }}
                buttonIcon={Apps}
                dropdownList={[
                  <Link to="/edit-profile" className={classes.dropdownLink}>
                    <FaUserEdit className={classes.dropdownIcons} /> Edit
                    Profile
                  </Link>,
                  // <Link to="/edit-password" className={classes.dropdownLink}>
                  //   <FaExpeditedssl className={classes.dropdownIcons} />{" "}
                  //   Generate Password
                  // </Link>,
                  <Link to="/add-address" className={classes.dropdownLink}>
                    <FaAddressBook className={classes.dropdownIcons} /> Add
                    Address
                  </Link>,
                  <Link
                    to="/edit-update-address"
                    className={classes.dropdownLink}
                  >
                    <FaAddressBook className={classes.dropdownIcons} /> Update
                    Address
                  </Link>,
                  <Link to="/todays-deal" className={classes.dropdownLink}>
                    <ShoppingBasketIcon className={classes.dropdownIcons} />
                    Todays Deals
                  </Link>,
                  <Link to="/my-order" className={classes.dropdownLink}>
                    <RiShoppingBagLine className={classes.dropdownIcons} /> My
                    Order
                  </Link>,
                  <>
                    {activeSession ? (
                      <Link
                        to=""
                        className={classes.dropdownLink}
                        onClick={handleLogOut}
                      >
                        <IoMdLogOut className={classes.dropdownIcons} /> Log Out
                      </Link>
                    ) : (
                      ""
                    )}
                  </>,
                ]}
              />
            </ListItem>
          </span>
        )}
      </List>
    </>
  );
}

HeaderLinks.defaultProps = {
  hoverColor: "primary",
};

HeaderLinks.propTypes = {
  dropdownHoverColor: PropTypes.oneOf([
    "dark",
    "primary",
    "info",
    "success",
    "warning",
    "danger",
    "rose",
  ]),
};
