import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import Muted from "components/Typography/Muted.js";
import GoldPlated from "assets/img/faces/Gold-Plated-Bangles.jpg";
import MeenaBangles from "assets/img/faces/Gold-Plated-Meena-Bangles.jpg";
import ToneBracelet from "assets/img/faces/3-tone-bangle-bracelet.jpg";
import SilverBangles from "assets/img/faces/Silver-Bangles-&-Kada.jpg";
 
import teamsStyle from "assets/jss/material-kit-pro-react/views/sectionsSections/teamsStyle.js";

const useStyles = makeStyles(teamsStyle);

export default function BanglesCategory({ ...rest }) {
    const classes = useStyles();
    return (
        <div className="cd-section" {...rest}>
            <Helmet>
                {/* <title>Plating Category</title> */}
                <meta name="description" content="Zero making charges on minimum purchase of 1000 boxes on your first order Online" />
                <meta name="keywords" content="Gold Plated, Meena Bangles, Rose Gold Bangles, 3 Tone Bangles, Tri Color 7 Semanario, Silver Bangles & Kada" />
            </Helmet>
            <div className={classes.team}>
                <div className={classes.container}>
                    <GridContainer>
                        <GridItem
                            xs={12}
                            sm={8}
                            md={8}
                            className={
                                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
                            }
                        >
                            <h3 className={classes.title}>Life Time Offer</h3>
                            <h5 className={classes.description}>
                            We make bangles at the lowest making charges rate. We provide additional benefits to clients; we will print the brand name of the client on the boxes for free of cost if the minimum quantity of the order is 4000 boxes or above.
                            </h5>
                        </GridItem>
                    </GridContainer>
                    <GridContainer>
                        <GridItem xs={12} sm={6} md={6}>
                            <Card profile plain className={classes.card3}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <CardHeader image plain>
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                <img src={GoldPlated} alt="..." />
                                            </a>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${GoldPlated})`,
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <CardBody plain>
                                            <h4 className={classes.cardTitle}>Gold Plated</h4>
                                            <Muted>
                                                <h6 className={classes.cardCategory}>8/3MM bangles</h6>
                                            </Muted>
                                            <br />
                                            <Muted>
                                                <h6 className={classes.cardCategory}>6 pcs in 1 set</h6>
                                            </Muted>
                                            <br />
                                            <Muted>
                                                <h6 className={classes.cardCategory}>minimum order quantity: 220 boxes</h6>
                                            </Muted>
                                            {/* <p className={classes.description}>
                                                Don{"'"}t be scared of the truth because we need to
                                                restart the human foundation in truth...
                                            </p> */}
                                        </CardBody>
                                        {/* <CardFooter profile plain>
                                            <Button justIcon simple color="twitter">
                                                <i className="fab fa-twitter" />
                                            </Button>
                                            <Button justIcon simple color="facebook">
                                                <i className="fab fa-facebook-square" />
                                            </Button>
                                            <Button justIcon simple color="google">
                                                <i className="fab fa-google" />
                                            </Button>
                                        </CardFooter> */}
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <Card profile plain className={classes.card3}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <CardHeader image plain>
                                            <a href="/bangles-product?id=296&catdesc=Bangles&cat1desc=6MM&Plating=Gold%20Plated%20&%20Meena">
                                                <img src={MeenaBangles} alt="..." />
                                            </a>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${MeenaBangles})`,
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <CardBody plain>
                                            <h4 className={classes.cardTitle}>Meena Bangles</h4>
                                            <Muted>
                                                <h6 className={classes.cardCategory}>
                                                5.5MM  Meena bangles
                                                </h6>
                                            </Muted>
                                            <br />
                                            <Muted>
                                                <h6 className={classes.cardCategory}>
                                                4 pcs in 1 set
                                                </h6>
                                            </Muted>
                                            <br />
                                            <Muted>
                                                <h6 className={classes.cardCategory}>
                                                minimum order quantity: 220 boxes
                                                </h6>
                                            </Muted>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <Card profile plain className={classes.card3}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <CardHeader image plain>
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                <img src={ToneBracelet} alt="..." />
                                            </a>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${ToneBracelet})`,
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <CardBody plain>
                                            <h4 className={classes.cardTitle}>3 Tone Bangles</h4>
                                            <Muted>
                                                <h6 className={classes.cardCategory}>3MM 3Tone plated bangles</h6>
                                            </Muted>
                                            <br />
                                            <Muted>
                                                <h6 className={classes.cardCategory}>7 pcs in 1 set</h6>
                                            </Muted>
                                            <br />
                                            <Muted>
                                                <h6 className={classes.cardCategory}>minimum order quantity: 220 boxes</h6>
                                            </Muted>
                                        </CardBody>
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                        <GridItem xs={12} sm={6} md={6}>
                            <Card profile plain className={classes.card3}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={5}>
                                        <CardHeader image plain>
                                            <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                                <img src={SilverBangles} alt="..." />
                                            </a>
                                            <div
                                                className={classes.coloredShadow}
                                                style={{
                                                    backgroundImage: `url(${SilverBangles})`,
                                                    opacity: "1",
                                                }}
                                            />
                                        </CardHeader>
                                    </GridItem>
                                    <GridItem xs={12} sm={12} md={7}>
                                        <CardBody plain>
                                            <h4 className={classes.cardTitle}>Bright Silver & Gold Kada</h4>
                                            <Muted>
                                                <h6 className={classes.cardCategory}>15MM Silver and Gold plated <br /> men{"'"}s Punjabi Kada</h6>
                                            </Muted>
                                            <br />
                                            <Muted>
                                                <h6 className={classes.cardCategory}>1 piece set</h6>
                                            </Muted>
                                            <br />
                                            <Muted>
                                                <h6 className={classes.cardCategory}>minimum order quantity: 220 boxes</h6>
                                            </Muted>
                                            
                                        </CardBody>
                                        
                                    </GridItem>
                                </GridContainer>
                            </Card>
                        </GridItem>
                    </GridContainer>
                </div>
            </div>
        </div>

    )
}