const BanglesImage = [
    { id: 1, img: "Img/Golwire-Small.jpg", desp: "Bangles crafted with care and love that add elegance to your personality", prodHead: "Golwire Small", link: "bangles-product?id=103&catdesc=Bangles&cat1desc=Golwire%20Small&Plating=Gold%20Plating"},
    { id: 2, img: "Img/Golwire-Medium.jpg", desp: "We design jewelry according to your demands and requirements", prodHead: "Golwire Medium", link: "bangles-product?id=107&catdesc=Bangles&cat1desc=Golwire%20Medium&Plating=Gold%20Plating" },
    { id: 3, img: "Img/1MM-Gold-plated-bangles.jpg", desp: "Our designs never go out of fashion; our work will exceed your expectation.", prodHead: "1MM Gold Plated", link: "bangles-product?id=242&catdesc=Bangles&cat1desc=1MM&Plating=Gold%20Plating"},
    { id: 4, img: "Img/Gold-Plated-1.5MM-Bangles.jpg", desp: "Come look at them, because there is no harm in looking", prodHead: "1.5MM Gold Plated", link: "bangles-product?id=128&catdesc=Bangles&cat1desc=1.5MM&Plating=Gold%20Plating"},
    { id: 5, img: "Img/2MM-Micro-gold-plated-bangles.jpg", desp: "Kings of the bangle world, Finally a place you have looking for", prodHead: "2MM Gold", link: "bangles-product?id=102&catdesc=Bangles&cat1desc=Golwire%20small&Plating=Gold%20Plating"},
    { id: 6, img: "Img/gold-plated-bangles-2.5MM.jpg", desp: "Purchase with affordability and Walk with the trend and design", prodHead: "2.5MM Gold Plated",  link: "bangles-product?id=108&catdesc=Bangles&cat1desc=Golwire%20Medium&Plating=Gold%20Plating"},
    { id: 7, img: "Img/3MM-Tricolor-bangles.jpg", desp: "The design may change, choose with your range", prodHead: "3MM Tri Color"},
    { id: 8, img: "Img/3.5MM-gold-bangles.jpg", desp: "Precision in every inch of our work, Fashion and style in everything we make.", prodHead: "3.5MM Micro Gold Plated", link: "bangles-product?id=146&catdesc=Bangles&cat1desc=3MM&Plating=Gold%20Plated"},
    { id: 9, img: "Img/4MM-gold-plated-bangle.jpg", desp: "Come if you want something better, because we never compromise with quality.", prodHead: "4MM Gold Plated", link: "bangles-product?id=191&catdesc=Bangles&cat1desc=4MM&Plating=Gold%20Plated"},
    { id: 10, img: "Img/4.5MM-gold-plated-bangles.jpg", desp: "Stunning design with Refreshing Ideas that you can’t ignore.", prodHead: "4.5MM Gold Plated", link: "bangles-product?id=237&catdesc=Bangles&cat1desc=4.5MM&Plating=Gold%20Plated"},
    { id: 11, img: "Img/5MM-gold-plated-bangles.jpg", desp: "Bangles made from our hearts to your soul, you will never forget what you see here", prodHead: "5MM Gold Plated", link: "bangles-product?id=250&catdesc=Bangles&cat1desc=5MM&Plating=Gold%20Plated"},
    { id: 12, img: "Img/5.2MM-gold-plated-bangles.jpg", desp: "Enjoy the convenience of buying bangles with us", prodHead: "5.2MM Gold Plated", link: "bangles-product?id=290&catdesc=Bangles&cat1desc=5.5MM&Plating=Gold%20Plated"},
    { id: 13, img: "Img/5-3MM-Odisha-pola-bangles.jpg", desp: "Come if want honesty, perfection, and Creative Designs", prodHead: "5/3MM Odisha Pola", link: "bangles-product?id=350&catdesc=Bangles&cat1desc=5/3MM&Plating=Gold%20Plated"},
    { id: 14, img: "Img/5.5MM-micro-gold-plated-bangles.jpg", desp: "Choose Comfortable refreshing Ideas according to your wish", prodHead: "5.5MM Gold Plated", link: "bangles-product?id=296&catdesc=Bangles&cat1desc=6MM&Plating=Gold%20Plated%20&%20Meena"},
    { id: 15, img: "Img/6MM-7-Semanario-bangles.jpg", desp: "Get a perfect match, Find the ‘Better’ out of ‘Best’", prodHead: "6MM 7 Semanario", link: "bangles-product?id=308&catdesc=Bangles&cat1desc=6MM&Plating=3tone"},
    { id: 16, img: "Img/6.5MM-gold-plated-bangles.jpg", desp: "The true beauty of bangles is due to their naturally customizable design", prodHead: "6.5MM Gold Plated", link: "bangles-product?id=299&catdesc=Bangles&cat1desc=6MM&Plating=Gold%20Plated"},
    { id: 17, img: "Img/7MM-7-semanario.jpg", desp: "The fashion that never gets old, Wear at the time of ", prodHead: "7MM 7 Semanario", link: "bangles-product?id=318&catdesc=Bangles&cat1desc=6MM&Plating=3tone"},
    { id: 18, img: "Img/7.2MM-7semanario-bangles.jpg", desp: "Accurate designs just the way you wanted for special occasions", prodHead: "7.2MM 7 Semanario", link: "bangles-product?id=392&catdesc=BANGLES&cat1desc=7.2MM&Plating=3tone"},
    { id: 19, img: "Img/7-3MM-gold-plated-bangls.jpg", desp: "Feel the difference by wearing our bangles, and Take a moment to praise our elegant collection.", prodHead: "7/3MM Gold Plated"},
    { id: 20, img: "Img/7.8MM-micro-gold-plated-bangles.jpg", desp: "Look as beautiful outside as you are within. Look elegant and shine bright.", prodHead: "7.8MM Micro Gold Plated", link: "bangles-product?id=405&catdesc=Bangles&cat1desc=9.2MM&Plating=Gold%20Plated"},
    { id: 21, img: "Img/8MM-3tone-plated-bangles.jpg", desp: "A solution to your gold and silver desire.", prodHead: "8MM 3 Tone Plated", link: "bangles-product?id=386&catdesc=Bangles&cat1desc=8MM&Plating=3tone"},
    { id: 22, img: "Img/8-3MM-gold-plated-bngles.jpg", desp: "We provide satisfaction with our bangles. If you can’t stop thinking about it, buy it", prodHead: "8/3MM Gold Plated"},
    { id: 23, img: "Img/9MM-goldplated-bangles.jpg", desp: "A bangle has the power to be this one little thing that can make you feel unique.", prodHead: "9MM Gold Plated", link: "bangles-product?id=407&catdesc=Bangles&cat1desc=9.2MM&Plating=Gold%20Plated"},
    { id: 24, img: "Img/9.2MM-goldplated-bangles.jpg", desp: "Sparkling with beauty, shining with pride, captivating charm", prodHead: "9.2MM Gold Plated", link: "bangles-product?id=408&catdesc=Bangles&cat1desc=9.2MM&Plating=Gold%20Plated"},
    { id: 25, img: "Img/9-3MM-gold-bangles.jpg", desp: "An Add-on to your impression, Something Shiny. Something Beautiful.", prodHead: "9/3MM Gold Plated"},
    { id: 26, img: "Img/10MM-4pcset-gold-plated-bangles.jpg", desp: "Do not limit your style, buy bangles and Enjoy the moment of ethnic beauty", prodHead: "10MM Gold Plated"},
    { id: 27, img: "Img/11MM-3tone-bangles.jpg", desp: "Gold or silver, choose what looks good on you. Bright and shiny jewelry that is perfect for you.", prodHead: "11MM 3 Tone"},
    { id: 28, img: "Img/11-3MM-3tone-plated-bangles.jpg", desp: "Dedication and creativity, quality and lowest price are what we aim for", prodHead: "11/3MM 3 Tone Plated"},
    { id: 29, img: "Img/11-4MM-tricolour-bangles.jpg", desp: "Bangles with high quality, fine metal, low making charges and attractive rates.", prodHead: "11/4MM Tri Color"},
    { id: 30, img: "Img/11-5MM-3tone-plated-bangles.jpg", desp: "The intricate designs which you will not find anywhere else.", prodHead: "11/5MM 3 Tone Plated"},
    { id: 31, img: "Img/12MM-gold-plated-bangles.jpg", desp: "Bangles for Those Who Want to Sparkle with Style and Natural Beauty.", prodHead: "12MM Gold Plated"},
    { id: 32, img: "Img/12-3MM-gold-plated-bangles.jpg", desp: "A Good Pair of bangles Illuminate the Skin and Accentuate Your Look.", prodHead: "12/3MM Gold Plated"},
    { id: 33, img: "Img/15MM-Silver-and-gold-punjabi-kada.jpg", desp: "You’re Not Fully Dressed until a set of bangles Add Some Sparkle and beauty on it.", prodHead: "15MM Silver & Gold"},
    { id: 34, img: "Img/19MM-2pcs-gold-plated-bangles.jpg", desp: "Bangles as Unique as the Woman Who Wears It. Bangles are Like a Tribute to Being a Woman.", prodHead: "19MM Gold Plated"},
    { id: 35, img: "Img/20MM-Tricolour-bangles.jpg", desp: "Unleashing the beauty of the Bangles with silver, Gold, Rose, and Rhodium", prodHead: "20MM Tricolor", link: "bangles-product?id=474&catdesc=BANGLES&cat1desc=20MM&Plating=3tone"},
    { id: 36, img: "Img/20-3MM-Tricolour-bangles.jpg", desp: "You can choose the type of bangles design you would like to wear as it comes in different styles and fashion.", prodHead: "20/3MM Tricolor", link: "bangles-product?id=608&catdesc=BANGLES&cat1desc=20/3MM&Plating=3tone"},


];

export default BanglesImage;