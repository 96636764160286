/*eslint-disable*/
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router";
import classNames from "classnames";
import { localApi } from "utils/headApi";
import { imgApi } from "utils/imgApi";
import { makeStyles } from "@material-ui/core/styles";
import Favorite from "@material-ui/icons/Favorite";
import Parallax from "components/Parallax/Parallax.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Spinner from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import CornerRibbon from "react-corner-ribbon";

import productStyle from "assets/jss/material-kit-pro-react/views/productStyle.js";
import aboutUsStyle from "assets/jss/material-kit-pro-react/views/blogPostsPageStyle.js";
import popOver from "assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles.js";

import BanglesHeader from "./Section/BanglesHeader.js";
import HeaderLink from "./Section/BanglesHeaderLink.js";
import BanglesFooter from "./Section/BanglesFooter.js";
import logo from "assets/img/examples/apple-bangles-org-icon.png";

const useStyles = makeStyles(productStyle);
const useStyle = makeStyles(aboutUsStyle);
const useStylPop = makeStyles(popOver);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function SizeMMlist() {
  const [prodList, setProdList] = React.useState([]);
  const [loginModal, setLoginModal] = React.useState(false);

  const classes = useStyles();
  const classe = useStyle();
  const classPop = useStylPop();

  const activeSession = sessionStorage.getItem("sessionData");
  const userObj = JSON.parse(sessionStorage.getItem("sessionData"));

  // const headApi = `https://api.bangles.org.in/api/rest`;
  // const imgApi = `https://imgstore.bangles.org.in`;

  const { search } = useLocation();
  const catdesc = new URLSearchParams(search).get("catdesc");

  useEffect(() => {
    const catdesc = new URLSearchParams(search).get("catdesc");
    const cat1desc = new URLSearchParams(search).get("cat1desc");

    const fetchProductData = async () => {
      try {
        const response = await fetch(
          localApi +
          `/product/list/byCatMM?admin_id=2&active=1&catdesc=${catdesc}&cat1desc=${cat1desc}`
        );
        const json = await response.json();
        setProdList(json);
        // console.log(json[0].stockSize.length);
      } catch (error) {
        console.log("error", error);
      }
    };

    fetchProductData();

    setTimeout(hideAlertPop, 1000);
    setLoginModal(true);
  }, [search]);

  const hideAlertPop = () => {
    setLoginModal(false);
  };

  return (
    <div className={classes.productPage}>
      <Helmet>
        <title>Bangles Size</title>
      </Helmet>
      <BanglesHeader
        brand="Home"
        links={<HeaderLink dropdownHoverColor="info" />}
        fixed
        color="transparent"
        changeColorOnScroll={{
          height: 100,
          color: "info",
        }}
      />
      <Parallax
        image={require("assets/img/bg6.jpg").default}
        filter="dark"
        className={classes.pageHeader}
      >
        <div className={classe.container}>
          <GridContainer justify="center">
            <GridItem
              md={8}
              sm={8}
              className={classNames(
                classe.mlAuto,
                classe.mrAuto,
                classe.textCenter
              )}
            >
              <h1 className={classe.title}>{catdesc}</h1>
              <h4 style={{ color: "white" }}>
                {catdesc === "BANGLES"
                  ? "Bangles to express yourself."
                  : catdesc === "EARRINGS"
                    ? "Style with precious simplicity."
                    : catdesc === "FINGER RINGS"
                      ? "The joy of wearing best."
                      : catdesc === "WATCH"
                        ? "You express, we present."
                        : catdesc === "PUNJABI KADA"
                          ? "A jewelry that connects heart."
                          : ""}
              </h4>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.section, classes.sectionGray)}>
        <div className={classes.container}>
          <Dialog
            style={{ marginTop: "10%" }}
            classes={{
              root: classPop.modalRoot,
              paper: classPop.modal + " " + classPop.modalLogin,
            }}
            open={loginModal}
            TransitionComponent={Transition}
            keepMounted
            // onClose={() => setLoginModal(false)}
            aria-labelledby="login-modal-slide-title"
            aria-describedby="login-modal-slide-description"
          >
            <DialogTitle
              id="login-modal-slide-title"
              disableTypography
              className={classPop.modalHeader}
            >
              <h5>Loading...</h5>
              <DialogContent
                id="login-modal-slide-description"
                className={classPop.modalBody}
              >
                <GridContainer justify="center">
                  <Spinner
                    onChange={() => hideAlertPop()}
                    style={{ color: "#00acc1" }}
                  />
                </GridContainer>
                <br />
              </DialogContent>
            </DialogTitle>
          </Dialog>
          <div className={classes.relatedProducts}>
            <h3 className={classNames(classes.title, classes.textCenter)}>
              Popular items Today:
            </h3>
            <GridContainer>
              {prodList &&
                prodList.map((prod) => (
                  <GridItem sm={6} md={3} key={prod.id}>
                    <Card product>
                      <CardHeader image>
                        <CornerRibbon
                          position={
                            prod.stockSize.length === 1
                              ? "top-left"
                              : "top-right"
                          } // position="top-right" // OPTIONAL, default as "top-right"
                          fontColor="#f0f0f0" // OPTIONAL, default as "#f0f0f0"
                          backgroundColor="#00acc1" // OPTIONAL, default as "#2c7"
                          containerStyle={{}} // OPTIONAL, style of the ribbon
                          style={{ fontSize: "13px", fontWeight: "bold" }} // OPTIONAL, style of ribbon content
                          className="" // OPTIONAL, css class of ribbon
                        >
                          {" "}
                          {prod.stockSize.length === 1
                            ? "In Stock"
                            : "Make To Order"}
                        </CornerRibbon>
                        <a
                          href={`/bangles-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                        // &Plating=${prod.plating.description.slice(8)}
                        >
                          {/* <img src={cardProduct1} alt="cardProduct" /> */}
                          {/* {prod.design1.img1active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design1.image1}
                              alt="..."
                            />
                          ) : prod.design2.img2active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design2.image2}
                              alt="..."
                            />
                          ) : prod.design3.img3active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design3.image3}
                              alt="..."
                            />
                          ) : prod.design4.img4active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design4.image4}
                              alt="..."
                            />
                          ) : prod.design5.img5active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design5.image5}
                              alt="..."
                            />
                          ) : prod.design6.img6active === 1 ? (
                            <img
                              src={imgApi + "" + prod.design6.image6}
                              alt="..."
                            />
                          ) : (
                            ""
                          )} */}
                          <img
                            src={
                              prod.productImage[0].cropImageUrl +
                              "?id=" +
                              prod.productImage[0].imgId
                            }
                            alt="..."
                          />
                          <p
                            style={{
                              position: "absolute",
                              top: "65%",
                              left: "50%",
                              width: "65px",
                              height: "65px",
                              transform: "translate(-50%, -50%)",
                            }}
                          >
                            <img src={logo} alt="..." />
                            {/* {imgApi + "" + prod.design1.image1 !==
                            imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design2.image2 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design3.image3 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design4.image4 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design5.image5 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : imgApi + "" + prod.design6.image6 !==
                              imgApi + "" + "/crop_img/null" ? (
                              <img src={logo} alt="..." />
                            ) : (
                              ""
                            )} */}
                          </p>
                        </a>
                      </CardHeader>
                      <CardBody>
                        <h6
                          className={classNames(
                            classes.cardCategory,
                            classes.textRose
                          )}
                        >
                          {prod.productCategory.title +
                            " " +
                            prod.productParent.title}{" "}
                          <br /> {prod.itemCodeNumber}-{prod.id}
                        </h6>
                        <h4 className={classes.cardTitle}>
                          {prod.prodDescription}
                        </h4>
                        <div className={classes.cardDescription}>
                          {(prod.readyGram * prod.productSets.pcs).toFixed(4)}{" "}
                          Avg Gram Of {prod.productSets.setDesc} {"and "}
                          {prod.productBox.boxDesc}
                          {","} {prod.cartDescription}
                          {"."}
                        </div>
                      </CardBody>
                      <CardFooter className={classes.justifyContentBetween}>
                        <div className={classes.price}>
                          {prod.gstCash === true ? (
                            <>
                              <h5>
                                <span style={{ color: "red" }}>
                                  Price ₹ {(prod.taxInclude / prod.productBox.box).toFixed(2) + " Inc."}
                                </span>
                              </h5>
                            </>
                          ) : (
                            <>
                              <h5>
                                <span style={{ color: "black" }}>
                                  Price ₹ {(prod.cashPrice / prod.productBox.box).toFixed(2)}
                                </span>
                              </h5>
                            </>
                          )}
                          {/* {activeSession ? "" : prod.taxCalculation.profitactive === 1 ? <h5>Price <span style={{ color: "red" }}>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-</span></h5> : <h5>Price <span>₹ {(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/-*</span></h5>}

                                                {!activeSession ? <h6><a href="/bangles-login">Login</a> to see prices and exciting offers.
                                                </h6> : prod.taxCalculation.profitactive === 1 ? <h6>Price <span style={{ textDecoration: "line-through", color: "red" }}>₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span>{" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6> :
                                                    <h6>Price <span style={{ textDecoration: "line-through" }}>₹{(prod.taxCalculation.taxincl / prod.box.box).toFixed(2)}/</span> {" "} ₹ {(prod.taxCalculation.cash / prod.box.box).toFixed(2)}/-*</h6>
                                                } */}

                          {/* {!activeSession ? (
                            prod.taxCalculation.profitactive === 1 ? (
                              prod.taxCalculation.cash_gst === 1 ? (
                                <>
                                  <h5>
                                    <span style={{ color: "red" }}>
                                      Price ₹{" "}
                                      {(
                                        prod.taxCalculation.taxincl /
                                        prod.box.box
                                      ).toFixed(2)}
                                      /-
                                    </span>
                                  </h5>{" "}
                                  <h6>
                                    <a href="/bangles-login">Login</a> to see
                                    prices and exciting offers.
                                  </h6>{" "}
                                </>
                              ) : (
                                <h5>
                                  Price{" "}
                                  <span>
                                    ₹{" "}
                                    {(
                                      prod.taxCalculation.cash / prod.box.box
                                    ).toFixed(2)}
                                    /-*
                                  </span>
                                </h5>
                              )
                            ) : prod.taxCalculation.cash_gst === 1 ? (
                              <>
                                <h5>
                                  <span style={{ color: "#9c27b0" }}>
                                    Price ₹{" "}
                                    {(
                                      prod.taxCalculation.taxincl / prod.box.box
                                    ).toFixed(2)}
                                    /-
                                  </span>
                                </h5>{" "}
                                <h6>
                                  <a href="/bangles-login">Login</a> to see
                                  prices and exciting offers.
                                </h6>
                              </>
                            ) : (
                              <h5>
                                Price{" "}
                                <span>
                                  ₹{" "}
                                  {(
                                    prod.taxCalculation.cash / prod.box.box
                                  ).toFixed(2)}
                                  /-*
                                </span>
                              </h5>
                            )
                          ) : (
                            ""
                          )}

                          {activeSession ? (
                            prod.taxCalculation.profitactive === 1 ? (
                              <h6>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "red",
                                  }}
                                >
                                  Price ₹
                                  {(
                                    prod.taxCalculation.taxincl / prod.box.box
                                  ).toFixed(2)}
                                  /
                                </span>{" "}
                                ₹{" "}
                                {(
                                  prod.taxCalculation.cash / prod.box.box
                                ).toFixed(2)}
                                /-*
                              </h6>
                            ) : (
                              <h6>
                                <span
                                  style={{
                                    textDecoration: "line-through",
                                    color: "#9c27b0",
                                  }}
                                >
                                  Price ₹
                                  {(
                                    prod.taxCalculation.taxincl / prod.box.box
                                  ).toFixed(2)}
                                  /
                                </span>{" "}
                                ₹{" "}
                                {(
                                  prod.taxCalculation.cash / prod.box.box
                                ).toFixed(2)}
                                /-*
                              </h6>
                            )
                          ) : (
                            ""
                          )} */}
                        </div>
                        <div className={classes.stats}>
                          <Tooltip
                            id="tooltip-top"
                            title="Save to Wishlist"
                            placement="top"
                            classes={{ tooltip: classes.tooltip }}
                          >
                            <Button justIcon color="rose" simple>
                              <Favorite />
                            </Button>
                          </Tooltip>
                        </div>
                      </CardFooter>
                      <CardFooter className={classes.justifyContentBetween}>
                        <div className={classes.price}>
                          <Button
                            color="info"
                            href={`/bangles-product?id=${prod.id}&catdesc=${prod.productCategory.title}&cat1desc=${prod.productParent.title}`}
                          // &Plating=${prod.plating.description.slice(8)}
                          >
                            {/* <Subject /> */}
                            Buy Now
                          </Button>
                        </div>
                        <div>
                          {/* <Button
                            color="success"
                            round
                            justIcon
                            href={`https://api.whatsapp.com/send/?phone=${
                              prod.user.whatsapp
                            }&text=https://bangles.org.in/bangles-product?id=${
                              prod.id
                            }%20${
                              prod.parent.title
                            }%20${prod.plating.description.slice(
                              8
                            )}&app_absent=0`}
                          >
                            <i
                              className="fab fa-whatsapp"
                              style={{ fontSize: "30px" }}
                            />
                          </Button> */}
                        </div>
                      </CardFooter>
                    </Card>
                  </GridItem>
                ))}
            </GridContainer>
          </div>
        </div>
      </div>
      <BanglesFooter />
    </div>
  );
}
